import { Box, Grid, Tab, Typography } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import { CompanyProjectsContainer } from "./Container";
import FormActionButton from "../../common/FormActionButton";
import DropdownField from "../../common/DropdownField";
import { companyProjectLabels } from "../../assets/labels/companyProjects";
import InputFileUpload from "../../common/InputFileUpload";
import UploadFiles from "../../common/UploadFiles";
import CancelIcon from "@mui/icons-material/Cancel";
import NumericInputField from "../../common/NumericInputField";
import CheckBox from "../../common/CheckBox";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
  } from '@mui/material';

const CompanyProjectsForm = () => {
	const {
		onChangeCompany,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		onClickSave,
		onClickReset,
		rowData,
		companyData,
		onChangeShortDescription,
		onChangeDescription,
		onchangeImage,
		onChangeCompanyName,
		onchangeMultipleImages,
		onchangeJSONFiles,
		onChangeSceneGuid,
		onChangeAgoraId,
		onRemoveFile,
		onRemoveShowCaseImages,
		onRemovePreviewImage,
		onChangePhotonSettings,
		handleNumericInput,
		onHandleCheckBox,
		onChangePhotonId,
		handleRemoveClick,
		handleRemoveConfirm,
		handleRemoveCancel,
		confirmationDialogOpen,
	} = CompanyProjectsContainer();

	return (
		<Grid className="global-form">
			<Box
				className="global-form-container"
				sx={{
					typography: "body1",
					height: "95%",
				}}
			>
				<TabContext value={tabIndex}>
					<Box className="global-form-tab-header">
						<TabList
							onChange={onChangeTabIndex}
							aria-label="lab API tabs example"
						>
							<Tab label="Company Projects" value="1" />
						</TabList>
					</Box>
					<TabPanel value="1" className="global-form-fields-container">
						<TextInputField
							label="companyId"
							value={rowData?.companyId}
							isRequired={true}
							isDisabled={showViewForm || showEditForm}
						/>
						<DropdownField
							label={companyProjectLabels.companyId}
							value={rowData?.companyId}
							onChangeEvent={onChangeCompany}
							data={companyData}
							isDisabled={showViewForm || showEditForm}
							isRequired={true}
						/>
						<TextInputField
							label={companyProjectLabels.company_name}
							value={rowData?.name}
							onChangeEvent={onChangeCompanyName}
							isRequired={true}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.shortDescription}
							value={rowData?.shortDescription.replace(/\\n/g, "\n")}
							onChangeEvent={onChangeShortDescription}
							isRequired={true}
							isDisabled={showViewForm}
							descriptionStyling={{ margin: "0px", width: "300px" }}
							size="small"
							multiline={true}
							rows={1}
						/>
						<TextInputField
							label={companyProjectLabels.description}
							value={rowData?.description.replace(/\\n/g, "\n")}
							onChangeEvent={onChangeDescription}
							isRequired={true}
							isDisabled={showViewForm}
							descriptionStyling={{ margin: "0px", width: "600px" }}
							size="large"
							multiline={true}
							rows={3}
						/>

						<Typography className="global-form-text">
							{companyProjectLabels.photon_settings}
						</Typography>
						<TextInputField
							label={companyProjectLabels.photonId_id}
							value={rowData?.photonId}
							onChangeEvent={(event) => onChangePhotonId(event)}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.app_id_real_time}
							value={rowData?.appIdRealtime}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "appIdRealtime")
							}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.app_id_fusion}
							value={rowData?.appIdFusion}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "appIdFusion")
							}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.app_id_chat}
							value={rowData?.appIdChat}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "appIdChat")
							}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.app_id_voice}
							value={rowData?.appIdVoice}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "appIdVoice")
							}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.app_version}
							value={rowData?.appVersion}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "appVersion")
							}
							isDisabled={showViewForm}
						/>
						<CheckBox
							id="user_name_server"
							label={companyProjectLabels.user_name_server}
							checked={rowData?.userNameServer}
							onChange={(event) => onHandleCheckBox(event, "userNameServer")}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.fixed_region}
							value={rowData?.fixedRegion}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "fixedRegion")
							}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.server}
							value={rowData?.server}
							onChangeEvent={(event) => onChangePhotonSettings(event, "server")}
							isDisabled={showViewForm}
						/>
						<NumericInputField
							label={companyProjectLabels.port}
							value={rowData?.port}
							onChangeEvent={(event) => handleNumericInput(event, "port")}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.proxy_server}
							value={rowData?.proxyServer}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "proxyServer")
							}
							isDisabled={showViewForm}
						/>
						<TextInputField
							label={companyProjectLabels.protocol}
							value={rowData?.protocol}
							onChangeEvent={(event) =>
								onChangePhotonSettings(event, "protocol")
							}
							isDisabled={showViewForm}
						/>
						<CheckBox
							id="enable_protocal_fallback"
							label={companyProjectLabels.enable_protocal_fallback}
							checked={rowData?.enableProtocalFallback}
							onChange={(event) =>
								onHandleCheckBox(event, "enableProtocalFallback")
							}
							isDisabled={showViewForm}
						/>
						<NumericInputField
							label={companyProjectLabels.auth_mode}
							value={rowData?.authMode}
							onChangeEvent={(event) => handleNumericInput(event, "authMode")}
							isDisabled={showViewForm}
						/>
						<CheckBox
							id="enable_lobby_fallback"
							label={companyProjectLabels.enable_lobby_statistics}
							checked={rowData?.enableLobbyStatistics}
							onChange={(event) =>
								onHandleCheckBox(event, "enableLobbyStatistics")
							}
							isDisabled={showViewForm}
						/>
						<NumericInputField
							label={companyProjectLabels.network_logging}
							value={rowData?.networkLogging}
							onChangeEvent={(event) =>
								handleNumericInput(event, "networkLogging")
							}
							isDisabled={showViewForm}
						/>

						<Typography className="global-form-text">
							{companyProjectLabels.main_images}
						</Typography>

						<Grid
							sx={{
								display: "flex",
							}}
						>
							<InputFileUpload
								label={companyProjectLabels.upload_main_image}
								onChangeEvent={onchangeImage}
								isRequired={true}
								isDisabled={showViewForm}
							/>
							{rowData?.previewImage ? (
								<>
									<img
										src={rowData?.previewImage}
										alt="preview_image"
										style={{
											width: 95,
											height: 95,
											margin: 8,
										}}
									/>
									<CancelIcon
										onClick={() => onRemovePreviewImage()}
										sx={{ color: "#ff0000", fontSize: 16 }}
									/>
								</>
							) : (
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#00000099",
										fontSize: 12,
									}}
								>
									{companyProjectLabels.upload_main_image}
								</Typography>
							)}
						</Grid>
						<Typography className="global-form-text">
							{companyProjectLabels.more_images}
						</Typography>

						<Grid
							sx={{
								display: "flex",
								marginRight: "10px",
							}}
						>
							<InputFileUpload
								label={companyProjectLabels.upload_more_images}
								onChangeEvent={onchangeMultipleImages}
								isRequired={true}
								isDisabled={showViewForm}
								initialFileUpload={rowData?.showCaseImages?.length > 0}
							/>
							{rowData?.showCaseImages &&
								rowData?.showCaseImages?.length > 0 ? (
								rowData?.showCaseImages?.map((imageUrl, index) => (
									<>
										<img
											src={imageUrl}
											key={index}
											alt="preview_image"
											style={{
												width: 95,
												height: 95,
												margin: 8,
											}}
										/>
										<CancelIcon
											onClick={() =>
												onRemoveShowCaseImages("showCaseImages", index)
											}
											sx={{ color: "#ff0000", fontSize: 16 }}
										/>
									</>
								))
							) : (
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#00000099",
										fontSize: 12,
									}}
								>
									{companyProjectLabels.upload_more_images}
								</Typography>
							)}
						</Grid>

						{/* Android Files */}

						<Grid
							sx={{
								display: "grid",
								border: "1px solid lightgray",
								margin: "10px",
							}}
						>
							<Typography className="global-form-text">
								{companyProjectLabels.android_files_title}
							</Typography>
							<TextInputField
								label={companyProjectLabels.scene_guide}
								value={rowData?.android?.sceneGuid}
								onChangeEvent={(event) => onChangeSceneGuid(event, "android")}
								isDisabled={showViewForm}
							/>

							<TextInputField
								label={companyProjectLabels.agora_id}
								value={rowData?.android?.agoraId}
								onChangeEvent={(event) => onChangeAgoraId(event, "android")}
								isDisabled={showViewForm}
							/>
							<UploadFiles
								label={companyProjectLabels.android_json_files_title}
								rowData={rowData?.android?.json}
								onChangeEvent={(event) =>
									onchangeJSONFiles("androidJSON", event)
								}
								uploadLabel={companyProjectLabels.android_JSON_files}
								acceptType="application/json"
								initialFileUpload={rowData?.android?.json.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("androidJSON", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.android_hash_files_title}
								rowData={rowData?.android?.hash}
								onChangeEvent={(event) =>
									onchangeJSONFiles("androidHash", event)
								}
								uploadLabel={companyProjectLabels.android_hash_files}
								acceptType="application/hash"
								initialFileUpload={rowData?.android?.hash.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("androidHash", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.android_bundle_files_title}
								rowData={rowData?.android?.bundle}
								onChangeEvent={(event) =>
									onchangeJSONFiles("androidBundle", event)
								}
								uploadLabel={companyProjectLabels.android_bundle_files}
								acceptType="application/bundle"
								initialFileUpload={rowData?.android?.bundle.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("androidBundle", event)}
							/>
						</Grid>

						{/* IOS Files  */}

						<Grid
							sx={{
								display: "grid",
								border: "1px solid lightgray",
								margin: "10px",
							}}
						>
							<Typography className="global-form-text">
								{companyProjectLabels.ios_files_title}
							</Typography>

							<TextInputField
								label={companyProjectLabels.scene_guide}
								value={rowData?.ios?.sceneGuid}
								onChangeEvent={(event) => onChangeSceneGuid(event, "ios")}
								isDisabled={showViewForm}
							/>
							<TextInputField
								label={companyProjectLabels.agora_id}
								value={rowData?.ios?.agoraId}
								onChangeEvent={(event) => onChangeAgoraId(event, "ios")}
								isDisabled={showViewForm}
							/>
							<UploadFiles
								label={companyProjectLabels.ios_json_files_title}
								rowData={rowData?.ios?.json}
								onChangeEvent={(event) => onchangeJSONFiles("iosJSON", event)}
								uploadLabel={companyProjectLabels.ios_JSON_files}
								acceptType="application/json"
								initialFileUpload={rowData?.ios?.json.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("iosJSON", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.ios_hash_files_title}
								rowData={rowData?.ios?.hash}
								onChangeEvent={(event) => onchangeJSONFiles("iosHash", event)}
								uploadLabel={companyProjectLabels.ios_hash_files}
								acceptType="application/hash"
								initialFileUpload={rowData?.ios?.hash?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("iosHash", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.ios_bundle_files_title}
								rowData={rowData?.ios?.bundle}
								onChangeEvent={(event) => onchangeJSONFiles("iosBundle", event)}
								uploadLabel={companyProjectLabels.ios_bundle_files}
								acceptType="application/bundle"
								initialFileUpload={rowData?.ios?.bundle?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("iosBundle", event)}
							/>
						</Grid>

						{/* Windows Files */}

						<Grid
							sx={{
								display: "grid",
								border: "1px solid lightgray",
								margin: "10px",
							}}
						>
							<Typography className="global-form-text">
								{companyProjectLabels.windows_files_title}
							</Typography>
							<TextInputField
								label={companyProjectLabels.scene_guide}
								value={rowData?.windows?.sceneGuid}
								onChangeEvent={(event) => onChangeSceneGuid(event, "windows")}
								isDisabled={showViewForm}
							/>
							<TextInputField
								label={companyProjectLabels.agora_id}
								value={rowData?.windows?.agoraId}
								onChangeEvent={(event) => onChangeAgoraId(event, "windows")}
								isDisabled={showViewForm}
							/>
							<UploadFiles
								label={companyProjectLabels.windows_json_files_title}
								rowData={rowData?.windows?.json}
								onChangeEvent={(event) =>
									onchangeJSONFiles("windowsJSON", event)
								}
								uploadLabel={companyProjectLabels.windows_JSON_files}
								acceptType="application/json"
								initialFileUpload={rowData?.windows?.json?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("windowsJSON", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.windows_hash_files_title}
								rowData={rowData?.windows?.hash}
								onChangeEvent={(event) =>
									onchangeJSONFiles("windowsHash", event)
								}
								uploadLabel={companyProjectLabels.windows_hash_files}
								acceptType="application/hash"
								initialFileUpload={rowData?.windows?.hash?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("windowsHash", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.windows_bundle_files_title}
								rowData={rowData?.windows?.bundle}
								onChangeEvent={(event) =>
									onchangeJSONFiles("windowsBundle", event)
								}
								uploadLabel={companyProjectLabels.windows_bundle_files}
								acceptType="application/bundle"
								initialFileUpload={rowData?.windows?.bundle?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("windowsBundle", event)}
							/>
						</Grid>

						{/* webgl */}

						<Grid
							sx={{
								display: "grid",
								border: "1px solid lightgray",
								margin: "10px",
							}}
						>
							<Typography className="global-form-text">
								{companyProjectLabels.webgl_files_title}
							</Typography>
							<TextInputField
								label={companyProjectLabels.scene_guide}
								value={rowData?.webgl?.sceneGuid}
								onChangeEvent={(event) => onChangeSceneGuid(event, "webgl")}
								isDisabled={showViewForm}
							/>
							<TextInputField
								label={companyProjectLabels.agora_id}
								value={rowData?.webgl?.agoraId}
								onChangeEvent={(event) => onChangeAgoraId(event, "webgl")}
								isDisabled={showViewForm}
							/>
							<UploadFiles
								label={companyProjectLabels.webgl_json_files_title}
								rowData={rowData?.webgl?.json}
								onChangeEvent={(event) => onchangeJSONFiles("webglJSON", event)}
								uploadLabel={companyProjectLabels.webgl_JSON_files}
								acceptType="application/json"
								initialFileUpload={rowData?.webgl?.json?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("webglJSON", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.webgl_hash_files_title}
								rowData={rowData?.webgl?.hash}
								onChangeEvent={(event) => onchangeJSONFiles("webglHash", event)}
								uploadLabel={companyProjectLabels.webgl_hash_files}
								acceptType="application/hash"
								initialFileUpload={rowData?.webgl?.hash?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("webglHash", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.webgl_bundle_files_title}
								rowData={rowData?.webgl?.bundle}
								onChangeEvent={(event) =>
									onchangeJSONFiles("webglBundle", event)
								}
								uploadLabel={companyProjectLabels.webgl_bundle_files}
								acceptType="application/bundle"
								initialFileUpload={rowData?.webgl?.bundle?.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("webglBundle", event)}
							/>
						</Grid>

						{/* {MAC Files} */}

						<Grid
							sx={{
								display: "grid",
								border: "1px solid lightgray",
								margin: "10px",
							}}
						>
							<Typography className="global-form-text">
								{companyProjectLabels.mac_files_title}
							</Typography>
							<TextInputField
								label={companyProjectLabels.scene_guide}
								value={rowData?.mac?.sceneGuid}
								onChangeEvent={(event) => onChangeSceneGuid(event, "mac")}
								isDisabled={showViewForm}
							/>

							<TextInputField
								label={companyProjectLabels.agora_id}
								value={rowData?.mac?.agoraId}
								onChangeEvent={(event) => onChangeAgoraId(event, "mac")}
								isDisabled={showViewForm}
							/>
							<UploadFiles
								label={companyProjectLabels.mac_json_files_title}
								rowData={rowData?.mac?.json}
								onChangeEvent={(event) => onchangeJSONFiles("macJSON", event)}
								uploadLabel={companyProjectLabels.mac_JSON_files}
								acceptType="application/json"
								initialFileUpload={rowData?.mac?.json.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("macJSON", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.mac_hash_files_title}
								rowData={rowData?.mac?.hash}
								onChangeEvent={(event) => onchangeJSONFiles("macHash", event)}
								uploadLabel={companyProjectLabels.mac_hash_files}
								acceptType="application/hash"
								initialFileUpload={rowData?.mac?.hash.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("macHash", event)}
							/>
							<UploadFiles
								label={companyProjectLabels.mac_bundle_files_title}
								rowData={rowData?.mac?.bundle}
								onChangeEvent={(event) => onchangeJSONFiles("macBundle", event)}
								uploadLabel={companyProjectLabels.mac_bundle_files}
								acceptType="application/bundle"
								initialFileUpload={rowData?.mac?.bundle.length > 0}
								isDisabled={showViewForm}
								onRemoveFile={(event) => handleRemoveClick("macBundle", event)}
							/>
						</Grid>
					</TabPanel>
					<FormActionButton
						onClickSave={onClickSave}
						onClickReset={onClickReset}
					/>
				</TabContext>
				<DialogBox />
			</Box>


			<Dialog
				open={confirmationDialogOpen}
				onClose={handleRemoveCancel}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to remove this file?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRemoveCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleRemoveConfirm} color='primary' autoFocus>
						Remove
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>


	);
};

export default CompanyProjectsForm;
