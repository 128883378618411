import React from "react";
import { useSelector } from "react-redux";
import CompanyTable from "./CompanyTable";
import CompanyForm from "./CompanyForm";

const Company = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

  return (
    <>
      {showTable && <CompanyTable />}
      {(showAddForm || showEditForm || showViewForm) && <CompanyForm />}
    </>
  );
};

export default Company;
