import {
  BrowserRouter,
  // createBrowserRouter,
  // createRoutesFromElements,
  Route,
  Routes,
} from "react-router-dom";
import Login from "../components/login/Login";
import Dashboard from "../components/dashboard/Dashboard";
import NotFound from "../components/notFound/NotFound";
import ForgotPassword from "../components/forgotPassword/ForgotPassword";
import ResetPassword from "../components/resetPassword/ResetPassword";
import OTPScreen from "../components/otp/OTPScreen";
import ProtectedRoute from "./ProtectedRoute";

// const Router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route>
//       <Route path="/login" element={<Login />} />
//       <Route path="/" element={<Dashboard />} />
//       <Route path="*" element={<NotFound />} />
//       <Route path="/forgotpassword" element={<ForgotPassword />} />
//       <Route path="/otp" element={<OTPScreen />} />
//     </Route>
//   )
// );

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/otp" element={<OTPScreen />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
