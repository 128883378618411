import React from "react";
import { Navigate } from "react-router-dom";
// import secureLocalStorage from "react-secure-storage";
// import { storageConstants } from "../utils/globalConstant";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children }) => {
  // here we need take token
  const token = Cookies.get("jwtToken");
  // const role = secureLocalStorage.getItem(storageConstants.role); // take jwtToken from cookies
  if (!token) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
