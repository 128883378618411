export const companyLabels = {
  company_name_field: "companyName",
  company_name: "Company Name",

  email_field: "email",
  email: "Email",

  created_date_field: "createdDate",
  created_date: "Created Date",

  subscription_start_date_field: "subscriptionStartDate",
  subscription_start_date: "Subscription Start Date",

  subscription_end_date_field: "subscriptionEndDate",
  subscription_end_date: "Subscription End Date",

  user_limit_field: "userLimit",
  user_limit: "No of users accounts allowed",

  company_plan_duration_field: "duration",
  company_plan_duration: "Duration (days)",

  payment_type_field: "paymentType",
  payment_type: "Type of Payment",

  company_role_field: "role",
  company_role: "Role",

  price_field: "price",
  price: "User account price (USD)",

  subscription_status_field: "subscriptionStatus",
  subscription_status: "Subscription Status",
  company_profile_image_field: "image",
  company_profile_image: "Company Profile",

  company_status_field: "active",
  company_status: "Status",
};
