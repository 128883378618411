import { Box, Collapse, Grid } from "@mui/material";
import React from "react";
import "./Style.scss";
import {
	Business as CompanyIcon,
	ColorLens as Product,
	Person3 as PersonIcon,
	// ExpandLess,
	// ExpandMore,
	// Menu as MenuIcon,
	Dashboard as DashboardIcon,
	AttachMoney as AttachMoneyIcon,
	Sell as SellIcon,
	Category as CategoryIcon,
	Campaign as CampaignIcon,
	// Settings as SettingsIcon,
	NotificationsNone as NotificationIcon,
	Info as InfoIcon,
	ContactSupport as ContactSupportIcon,
	Help as HelpIcon,
	PrivacyTip as PrivacyTipIcon,
	AcUnit as AcUnitIcon,
	TipsAndUpdates as TipsAndUpdatesIcon,
	KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
	// ArrowForwardIos as ArrowForwardIosIcon,
	KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
	SportsEsports as SportsEsportsIcon,
	ManageAccounts as ManageAccountsIcon,
} from "@mui/icons-material";
import { SidebarContainer } from "./Container";
import secureLocalStorage from "react-secure-storage";
import Option from "./Option";
import { sidebarOptions } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";

const Sidebar = (props) => {
	const { index, onChangeIndex } = props;
	const { showSideMenu, expandData, onChangeExpandCollapse } =
		SidebarContainer();

	return (
		<Grid>
			{/* <Container className="sd-logo-txt-container">
				<Typography variant="h6" component={"h3"} className="sd-aauti-txt ">
					Aauti
				</Typography>
				{showSideMenu && (
					<Typography
						variant="h6"
						component={"h3"}
						className="sd-verse-text sd-verse-text_show"
					>
						Verse
					</Typography>
				)}
			</Container> */}

			<Box
				spacing={2}
				sx={{
					mt: 10,
				}}
				direction={"column"}
			>
				<Option
					index={index}
					showSideMenu={showSideMenu}
					Icon={DashboardIcon}
					onChangeIndex={onChangeIndex}
					title={sidebarOptions.dashboard}
					value={0}
				/>
				{["SAD", "AD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
					<>
						<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CompanyIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.company}
							value={1}
						/>

						<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.companyProjects}
							value={25}
						/>

						<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.metaRob}
							value={28}
						/>
							<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.metaRobData}
							value={29}
						/>
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={
								expandData.pricing
									? KeyboardDoubleArrowDownIcon
									: KeyboardDoubleArrowRightIcon
							}
							isToggleBtn={true}
							handleClick={() => onChangeExpandCollapse("pricing")}
							title={sidebarOptions.pricing}
							value={-1}
							showArrow={true}
							arrowOpen={expandData.pricing}
							showArrayIcon={true}
						/> */}
						{/* <Collapse in={expandData.pricing} timeout="auto" unmountOnExit>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.pricingPlans}
								value={2}
								Icon={AttachMoneyIcon}
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.linePlans}
								value={3}
								Icon={SellIcon}
							/>
						</Collapse> */}
					</>
				)}
				{["CM", "CSAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
					<>
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={PersonIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.users}
							value={4}
						/> */}
						<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={Product}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.license}
							value={5}
						/>
							<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={PersonIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.adminUsers}
							value={8}
						/>
					</>
				)}
				{["CM", "CSAD", "CAD"].includes(secureLocalStorage.getItem(storageConstants.role)) && (
					<>
							<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={PersonIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.users}
							value={4}
						/>
					</>
				)}
				{["SAD"].includes(
					secureLocalStorage.getItem(storageConstants.role)
				) && (
					<>
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={ManageAccountsIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.companyConfig}
							value={24}
						/> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={
								expandData.configuration
									? KeyboardDoubleArrowDownIcon
									: KeyboardDoubleArrowRightIcon
							}
							isToggleBtn={true}
							handleClick={() => onChangeExpandCollapse("configuration")}
							title={sidebarOptions.configuration}
							value={-1}
							showArrow={true}
							arrowOpen={expandData.configuration}
							showArrayIcon={true}
						/> */}
						{/* <Collapse
							in={expandData.configuration}
							timeout="auto"
							unmountOnExit
						> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.aboutUs}
								value={11}
								Icon={InfoIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.contactUs}
								value={12}
								Icon={ContactSupportIcon}
							/> */}

							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.privacyPolicy}
								value={13}
								Icon={AttachMoneyIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.refundPolicy}
								value={14}
								Icon={PrivacyTipIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.termsAndCondition}
								value={15}
								Icon={AcUnitIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.pushNotificationKey}
								value={16}
								Icon={NotificationIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.newUpdate}
								value={17}
								Icon={TipsAndUpdatesIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.faq}
								value={18}
								Icon={HelpIcon}
							/>
							<Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.appDetails}
								value={19}
								Icon={InfoIcon}
							/> */}
						{/* </Collapse> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={SettingsIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.configurations}
							value={6}
						/> */}
						<Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={PersonIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.adminUsers}
							value={7}
						/>
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CampaignIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.campaign}
							value={9}
						/> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.productList}
							value={10}
						/> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={
								expandData.casualGames
									? KeyboardDoubleArrowDownIcon
									: KeyboardDoubleArrowRightIcon
							}
							isToggleBtn={true}
							handleClick={() => onChangeExpandCollapse("casualGames")}
							title={sidebarOptions.casualGames}
							value={-1}
							showArrow={true}
							arrowOpen={expandData.casualGames}
						/> */}
						{/* <Collapse in={expandData.casualGames} timeout="auto" unmountOnExit> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.games}
								value={23}
								Icon={SportsEsportsIcon}
							/> */}
							{/* <Option
								index={index}
								showSideMenu={showSideMenu}
								onChangeIndex={onChangeIndex}
								title={sidebarOptions.linePlans}
								value={3}
								Icon={SellIcon}
							/> */}
						{/* </Collapse> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.matchList}
							value={20}
						/> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.completedMatchList}
							value={21}
						/> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.withdrawMatchList}
							value={22}
						/> */}
						{/* <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.companyProjects}
							value={25}
						/> */}
						 <Option
							index={index}
							showSideMenu={showSideMenu}
							Icon={CategoryIcon}
							onChangeIndex={onChangeIndex}
							title={sidebarOptions.appConfiguration}
							value={27}
						/>
					</>
				)}
			</Box>
		</Grid>
	);
};

export default Sidebar;
