import moment from 'moment';
import { baseUrl } from '../service/baseUrl';
import Cookies from 'js-cookie';

export const checkNumericExpression = (value) => {
  var numbers = /^[0-9]+$/;
  return value.match(numbers);
};

export const checkEmailValidation = (value) => {
  var mailExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return value.match(mailExp);
};

export const getDateTimeFormat = (date) => {
  if ([undefined, null, ''].includes(date)) {
    return '';
  } else {
    const dateObject = new Date(date);
    const options = { timeZone: 'Asia/Kolkata' };
    const dateIST = dateObject?.toLocaleString('en-US', options);
    const moMdateObject = moment(dateIST, 'M/D/YYYY, h:mm:ss A');
    const dateTime = moMdateObject.format('Do MMMM YYYY, h:mm A');
    return dateTime;
  }
};

export const imageUpload = async (event) => {
  const formData = new FormData();
  formData.append('image', event.target.files[0]);
  try {
    const response = await fetch(`${baseUrl}common/imageUpload`, {
      method: 'POST',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      body: formData,
    });
    return await response.json();
  } catch (err) {
    console.log('error---', err);
  }
};

export const fileUpload = async (event, path) => {
  const formData = new FormData();
  formData.append('file', event.target.files[0]);
  formData.append('filepath', path);
  try {
    const response = await fetch(`${baseUrl}common/fileUpload`, {
      method: 'POST',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      body: formData,
    });
    return await response.json();
  } catch (err) {
    console.log('error---', err);
  }
};

export const deleteFiles = async (data) => {
  try {
    const response = await fetch(`${baseUrl}common/fileDelete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
        Authorization: Cookies.get('jwtToken'),
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log('error---', err);
  }
};

export const deleteImage = async (data) => {
  try {
    const response = await fetch(`${baseUrl}common/imageDelete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
        Authorization: Cookies.get('jwtToken'),
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log('error---', err);
  }
};