export const companyConstants = {
  subscriptionStatusValues: [
    { id: "Active", title: "Active" },
    { id: "Expired", title: "Expired" },
  ],
  companyRoles: [{ id: "CM", title: "Company" }],
  status: [
    {
      id: true,
      title: "Active",
    },
    {
      id: false,
      title: "Inactive",
    },
  ],
};

export const companyUrlConstants = {
  getTableDataUrl: "company/table",
  getSelectedCompanyDataUrl: "company/table",
  saveCompanyDataurl: "company/register",
  updateCompanyDataurl: "company/update",
  deleteCompanyDataUrl: "company/delete",
};
