import { useEffect, useState } from "react";
import { checkNumericExpression, imageUpload } from "../../utils/helper";
import { userConstants, userUrlConstants } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleLoadingView,
  toggleToastView,
} from "../../redux/reducer/appReducer";
import { userService } from "./Service";
import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

export const UserContainer = () => {
  const { showEditForm, showAddForm, showViewForm, selectedRowData } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
    CommonContainer();

  const userInfo = {
    companyId: secureLocalStorage.getItem(storageConstants.companyId),
    firstName: "",
    lastName: "",
    email: "",
    role: userConstants.userRoles[0].id,
    mobile: "",
    image: "",
    age: "",
    gender: userConstants.userGenders[0].id,
    active: true,
  };
  const [rowData, setRowData] = useState(userInfo);
  const [resetData, setResetData] = useState(userInfo);

  const getSelectedRowData = async () => {
    dispatch(toggleLoadingView(true));
    const data = await userService.getSelectedUserDataService(
      selectedRowData?._id,
      secureLocalStorage.getItem(storageConstants.companyId)
    );
    setRowData(data.result);
    setResetData(data.result);
    dispatch(toggleLoadingView(false));
  };

  useEffect(() => {
    if (showEditForm || showViewForm) {
      getSelectedRowData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onChangeFirstName = (event) => {
    const str = event.target.value;
    setRowData({
      ...rowData,
      firstName: str.charAt(0).toUpperCase() + str.slice(1),
    });
  };
  const onChangeLastName = (event) => {
    const str = event.target.value;
    setRowData({
      ...rowData,
      lastName: str.charAt(0).toUpperCase() + str.slice(1),
    });
  };
  const onChangeEmail = (event) => {
    setRowData({ ...rowData, email: event.target.value });
  };

  const onChangeGender = (event) => {
    setRowData({ ...rowData, gender: event.target.value });
  };
  const onChangeStatus = (event) => {
    setRowData({ ...rowData, active: event.target.value });
  };

  const onChangeAge = (event) => {
    if (checkNumericExpression(event.target.value)) {
      const value = parseInt(event.target.value);
      setRowData({ ...rowData, age: value.toString() });
    } else if (event.target.value === "") {
      setRowData({ ...rowData, age: "" });
    }
  };

  const onChangeRole = (event) => {
    setRowData({ ...rowData, role: event.target.value });
  };

  const onChangeMobile = (event) => {
    if (
      checkNumericExpression(event.target.value) &&
      event.target.value.length <= 10
    ) {
      setRowData({ ...rowData, mobile: event.target.value });
    } else if (event.target.value === "") {
      setRowData({ ...rowData, mobile: "" });
    }
  };

  const onChangeProfileImage = async (event) => {
    dispatch(toggleLoadingView(true));
    const res = await imageUpload(event);
    if (res.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "Please upload again",
          isError: true,
        })
      );
    } else {
      setRowData({ ...rowData, image: res?.result?.image });
    }
    dispatch(toggleLoadingView(false));
  };

  const onClickSave = async () => {
    if (
      rowData?.firstName.trim() === "" ||
      rowData?.email.trim() === "" ||
      rowData?.role.trim() === "" ||
      rowData?.mobile.trim() === ""
    ) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "* Please fill the required fields",
          isError: true,
        })
      );
    } else {
      dispatch(toggleLoadingView(true));
      let response;
      if (showAddForm) {
        response = await userService.saveUserDataService(rowData);
      } else {
        response = await userService.updateUserDataService(rowData);
      }
      if (response.result.length === 0) {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: true,
          })
        );
      } else {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: false,
          })
        );
        await dispatch(
          getTableData(
            `${userUrlConstants.getTableDataUrl}/${secureLocalStorage.getItem(
              storageConstants.companyId
            )}`
          )
        );
        onClickShowTable();
      }
      dispatch(toggleLoadingView(false));
    }
  };
  const onClickReset = () => {
    setRowData(resetData);
  };

  const onClickUserAddForm = async () => {
    const response = await userService.userLimitservice(
      secureLocalStorage.getItem(storageConstants.companyId)
    );
    if (response.result?.userLimitExceeded) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: userConstants.userLimitExceeds,
          isError: true,
        })
      );
    } else {
      onClickAddForm();
    }
  };
  const onClickUserEditForm = () => {
    onClickEditForm();
  };
  const onClickUserViewForm = () => {
    onClickViewForm();
  };

  const onClickDelete = async () => {
    dispatch(toggleLoadingView(true));
    const response = await userService.deleteUserDataService(
      selectedRowData?._id,
      secureLocalStorage.getItem(storageConstants.companyId)
    );
    dispatch(setSelectedRowData({}));
    if (response.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: true,
        })
      );
    } else {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: false,
        })
      );
      await dispatch(
        getTableData(
          `${userUrlConstants.getTableDataUrl}/${secureLocalStorage.getItem(
            storageConstants.companyId
          )}`
        )
      );
      dispatch(toggleLoadingView(false));
    }
  };

  return {
    onChangeFirstName,
    onChangeLastName,
    onChangeEmail,
    onChangeRole,
    onChangeMobile,
    tabIndex,
    onChangeTabIndex,
    showViewForm,
    showEditForm,
    onClickSave,
    onClickReset,
    rowData,
    onClickDelete,
    onClickUserAddForm,
    onClickUserEditForm,
    onClickUserViewForm,
    onChangeProfileImage,
    onChangeGender,
    onChangeAge,
    onChangeStatus,
  };
};
