import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { analyticsTableTitles, analytics } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";

export default function BasicTable({ title, data }) {
  const endUserCompanyUsers = data?.filter((item) => item.role === "EndUser")[0]
    ?.companyUsers;

  const columnData = ["Admin", "SuperAdmin"].includes(
    secureLocalStorage.getItem(storageConstants.role)
  )
    ? data
    : endUserCompanyUsers;

  return (
    <>
      <TableContainer component={Paper} className="table-main-titles">
        <Table aria-label="basic table">
          <TableHead>
            <TableRow>
              <TableCell className="name-cell-width">
                {analyticsTableTitles.name}
              </TableCell>
              <TableCell className="mobile-cell-width">
                {analyticsTableTitles.mobile}
              </TableCell>
              {title === analytics.newUserRegistration ? (
                <TableCell className="table-cell-width">
                  {analyticsTableTitles.email}
                </TableCell>
              ) : (
                <TableCell className="name-cell-width">
                  {analyticsTableTitles.coin}
                </TableCell>
              )}
              <TableCell className="name-cell-width">
                {analyticsTableTitles.companyName}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <div className="scrollable-container">
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="basic table">
            <TableBody>
              {columnData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell key={row.id} className="table-cell-width">
                    {row.firstName}
                  </TableCell>
                  <TableCell key={row.id} className="table-cell-width">
                    {row.mobile}
                  </TableCell>
                  <TableCell key={row.id} className="table-cell-width">
                    {row.email}{" "}
                  </TableCell>
                  <TableCell key={row.id} className="table-cell-width">
                    {row.firstName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
