import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLoginActions } from "../../redux/reducer/loginReducer";
import { checkEmailValidation } from "../../utils/helper";
import Image1 from "../../assets/images/image1.png";
import Image2 from "../../assets/images/image2.png";
import Image3 from "../../assets/images/image3.png";
import { images } from "./Constants";
import {
  toggleLoadingView,
  toggleToastView,
} from "../../redux/reducer/appReducer";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";
import Cookies from "js-cookie";
import { loginService } from "./Service";

export const LoginFunctionality = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [checkboxActive, setCheckBoxActive] = useState(false);
  const [savedEmailPassword, setSavedEmailPassword] = useState({});
  const [error, setError] = useState(false);
  const { userLogin } = useSelector((state) => state.userLoginDetails);

  useEffect(() => {
    if (userLogin.status === "success") {
      if (userLogin?.data?.result?.role === undefined) {
        setUserNotFound(true);
        dispatch(toggleLoadingView(false));
      } else {
        if (checkboxActive === true) {
          secureLocalStorage.setItem(storageConstants.email, email);
          secureLocalStorage.setItem(storageConstants.password, password);
        }
        secureLocalStorage.setItem(storageConstants.role, userLogin.data.result.role);
        secureLocalStorage.setItem(
          storageConstants.companyId,
          userLogin.data?.result?.companyId
        );

        Cookies.set("jwtToken", userLogin.data?.result?.jwtToken);
        dispatch(toggleLoadingView(false));
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogin.status]);

  const getHostnameDetails = async () => {
    dispatch(toggleLoadingView(true));
    console.log("====", window.location.hostname);
    const response = await loginService.getHostnameDetails(
      window.location.hostname
    );
    if (response?.result?.length === 0) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: true,
        })
      );
    } else {
      secureLocalStorage.setItem(
        storageConstants.secretkey,
        response?.result[0]?.secretkey
      );
    }
    dispatch(toggleLoadingView(false));
  };

  useEffect(() => {
    getHostnameDetails();
    secureLocalStorage.removeItem(storageConstants.companyId);
    secureLocalStorage.removeItem(storageConstants.selectedOption);
    secureLocalStorage.removeItem(storageConstants.role);
    const savedEmail = secureLocalStorage.getItem(storageConstants.email);
    const savedPassword = secureLocalStorage.getItem(storageConstants.password);
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setSavedEmailPassword({ email: savedEmail, password: savedPassword });
      setCheckBoxActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    setEmailError(false);
    setError(false);
    setUserNotFound(false);
    setCheckBoxActive(false);
    setSavedEmailPassword(enteredEmail);
  };

  const handlePasswordChange = (event) => {
    const enteredPassword = event.target.value;
    setPassword(enteredPassword);
    setPasswordError(false);
    setError(false);
    setUserNotFound(false);
    setCheckBoxActive(false);
    setSavedEmailPassword(enteredPassword);
  };

  const onSubmit = () => {
    if (email === "" && password === "") {
      setError(true);
      setEmailError(false);
      setPasswordError(false);
    } else if (email !== "" && password === "") {
      setEmailError(false);
      setPasswordError(true);
      setError(false);
    } else if (email === "" && password !== "") {
      setEmailError(true);
      setPasswordError(false);
      setError(false);
    } else {
      setEmailError(false);
      setPasswordError(false);
      setError(false);
      if (password !== "" && email !== "") {
        if (!checkEmailValidation(email)) {
          setEmailError(true);
          setUserNotFound(false);
        } else if (password.length < 5) {
          setPasswordError(true);
          setUserNotFound(false);
        } else {
          dispatch(userLoginActions({ email, password }));
          dispatch(toggleLoadingView(true));
        }
      }
    }
  };

  const handleCheckboxChange = () => {
    if (!checkboxActive && email === "" && password === "") {
      setError(true);
      setEmailError(false);
      setPasswordError(false);
    } else if (!checkboxActive && email !== "" && password === "") {
      setEmailError(false);
      setPasswordError(true);
      setError(false);
    } else if (!checkboxActive && email === "" && password !== "") {
      setEmailError(true);
      setPasswordError(false);
      setError(false);
    } else {
      setEmailError(false);
      setPasswordError(false);
      if (!checkboxActive && password !== "" && email !== "") {
        if (!checkEmailValidation(email)) {
          setEmailError(true);
          setUserNotFound(false);
        } else if (password.length < 5) {
          setPasswordError(true);
          setUserNotFound(false);
        } else {
          setCheckBoxActive(true);
        }
      } else {
        setCheckBoxActive(false);
      }
    }
  };

  var items = [
    {
      key: images.image1,
      image: Image2,
      name: "log1",
    },
    {
      key: images.image2,
      image: Image1,
      name: "log2",
    },
    {
      key: images.image3,
      image: Image3,
      name: "log2",
    },
  ];

  return {
    handleEmailChange,
    handlePasswordChange,
    handleCheckboxChange,
    onSubmit,
    items,
    checkboxActive,
    savedEmailPassword,
    userNotFound,
    error,
    userLogin,
    passwordError,
    emailError,
    password,
    email,
  };
};
