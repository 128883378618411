import { apiServices } from '../../service/apiService';
import { companyProjectsUrlConstants } from './Constants';

const getSelectedCompanyProjectsDataService = async (id) => {
  return await apiServices.getApi(
    `${companyProjectsUrlConstants.getSelectedCompanyProjectsDataUrl}/${id}`
  );
};

const saveCompanyProjectsDataService = async (data) => {
  return await apiServices.postApi(
    companyProjectsUrlConstants.saveCompanyProjectsDataurl,
    data
  );
};

const updateCompanyProjectsDataService = async (data) => {
  return await apiServices.updateApi(
    companyProjectsUrlConstants.updateCompanyProjectsDataurl,
    data
  );
};

const deleteCompanyProjectsDataService = async (id) => {
  return await apiServices.deleteApi(
    `${companyProjectsUrlConstants.deleteCompanyProjectsDataUrl}/${id}`
  );
};

const getCompanyNamesService = async () => {
  return await apiServices.getApi(
    companyProjectsUrlConstants.companyConfigCompanyData
  );
};

export const companyProjectsService = {
  getSelectedCompanyProjectsDataService,
  saveCompanyProjectsDataService,
  updateCompanyProjectsDataService,
  deleteCompanyProjectsDataService,
  getCompanyNamesService,
};
