export const appConfigConstants = {
	title: 'APP CONFIGURATION',
	editContent: "Edit Content",
	update: "Update",
	cancel: "Cancel",

}

export const appConfigLabels = {
	backgroundTimeOut: "Background Time Out",
	appVersion: "App Version",
	underMaintainance: "Under Maintainanace",
	upTime: "Up Time"
}

export const AppConfigUrlConstants = {
	getTableDataUrl: 'appConfiguration/getAppConfigData', // get appConfiguration Data
	getSelectedAppConfigDataUrl:  'appConfiguration/getOneAppConfig',
	saveAppConfigDataurl: 'appConfiguration/appConfiginsert', // insert appConfiguration data
	updateAppConfigDataurl: 'appConfiguration/updateAppConfigData', // update appConfiguration data
	deleteAppConfigDataUrl: 'appConfiguration/deleteAppConfig', // delete appConfiguration data
};