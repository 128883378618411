import { apiServices } from '../../service/apiService';
import { propertyUrlConstants } from './Constants';

const getSelectedPropertyDataService = async (id) => {
  return await apiServices.getApi(
    `${propertyUrlConstants.getSelectedPropertyDataUrl}/${id}`
  );
};

const savePropertyDataService = async (data) => {
  return await apiServices.postApi(
    propertyUrlConstants.savePropertyDataurl,
    data
  );
};

const updatePropertyDataService = async (data) => {
  return await apiServices.updateApi(
    propertyUrlConstants.updatePropertyDataurl,
    data
  );
};

const deletePropertyDataService = async (id) => {
  return await apiServices.deleteApi(
    `${propertyUrlConstants.deletePropertyDataUrl}/${id}`
  );
};

const getPropertyTypeService = async () => {
  return await apiServices.getApi("propertyType/getPropertyTypes");
};

const getFacingService = async () => {
  return await apiServices.getApi("facing/getFacingbits");
};


export const propertyService = {
  getSelectedPropertyDataService,
  savePropertyDataService,
  updatePropertyDataService,
  deletePropertyDataService,
  getPropertyTypeService,
  getFacingService,
};
