import React from "react";
import { useSelector } from "react-redux";
import PlatformAdminUserTable from "./PlatformAdminUserTable";
import PlatformAdminUserForm from "./PlatformAdminUserForm";

const PlatformAdminUser = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

  return (
    <>
      {showTable && <PlatformAdminUserTable />}
      {(showAddForm || showEditForm || showViewForm) && (
        <PlatformAdminUserForm />
      )}
    </>
  );
};

export default PlatformAdminUser;
