import { useContext } from "react";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../redux/reducer/loginReducer";
import {
  toggleAddForm,
  toggleEditForm,
  toggleSideMenu,
  toggleViewForm,
  toggleViewTable,
} from "../../redux/reducer/appReducer";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";
import Cookies from "js-cookie";

export const HeaderContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { showSideMenu } = useSelector((state) => state.app);
  const toggleTheme = () => {
    appContext?.toggleLightMode();
  };
  const onClicklogout = () => {
    // secureLocalStorage.removeItem(storageConstants.email);
    // secureLocalStorage.removeItem(storageConstants.role);
    // secureLocalStorage.removeItem(storageConstants.companyId);
    // secureLocalStorage.removeItem(storageConstants.forgotPasswordEmail);
    // secureLocalStorage.removeItem(storageConstants.setTimer);
    // secureLocalStorage.removeItem(storageConstants.password);
    secureLocalStorage.clear();
    Cookies.remove("jwtToken");
    dispatch(toggleViewTable(true));
    dispatch(toggleEditForm(false));
    dispatch(toggleViewForm(false));
    dispatch(toggleAddForm(false));
    dispatch(reset());
    navigate("/login");
  };

  const toggleSideMenuBar = () => {
    dispatch(toggleSideMenu(!showSideMenu));
  };

  const roleDisplay = () => {
    const role = secureLocalStorage.getItem(storageConstants.role);
    switch (role) {
      case "CM":
        return "Company";
      case "SAD" || "CSAD":
        return "Super Admin";
      case "AD" || "CAD":
        return "Admin";
      default:
        return "";
    }
  };

  return {
    toggleTheme,
    appContext,
    onClicklogout,
    toggleSideMenuBar,
    roleDisplay,
  };
};
