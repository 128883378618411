import { propertyLabels } from "../../assets/labels/propertyData";

export const propertyTableColumns = () => {
  const property_config_columns = [
    {
        field: propertyLabels.companyId_filed,
        headerName: propertyLabels.companyId,
    },
    {
        field: propertyLabels.property_id_field,
        headerName: propertyLabels.property_id,
    },
    {
        field: propertyLabels.property_name_field,
        headerName: propertyLabels.property_name
    },
    {
        field: propertyLabels.property_type_field,
        headerName: propertyLabels.property_type
    },
    {
        field: propertyLabels.facing_field,
        headerName: propertyLabels.facing,
    },
    {
        field: propertyLabels.capacity_field,
        headerName: propertyLabels.capacity
    },
    {
        field: propertyLabels.aminities_field,
        headerName: propertyLabels.aminities
    },
    {
        field: propertyLabels.description_field,
        headerName: propertyLabels.description
    },
    {
        field: propertyLabels.price_field,
        headerName: propertyLabels.price
    }
  ];

  return property_config_columns;
};