import React from "react";
import "./Style.scss";
import TextField from "@mui/material/TextField";
import Carousel from "react-material-ui-carousel";
import { Stack, Typography, Checkbox, Box, Button } from "@mui/material";
import { LoginFunctionality } from "./Container";
import { errorMessage, headings } from "./Constants";
import { Navigate, useNavigate } from "react-router-dom";
import CircleLoader from "../../common/CircleLoader";
import { useSelector } from "react-redux";
import aautiLogo from "../../assets/images/aauti_logo.png";
import Cookies from "js-cookie";

const Login = (props) => {
  const { isLoading } = useSelector((state) => state.app);
  const {
    handleEmailChange,
    handlePasswordChange,
    handleCheckboxChange,
    onSubmit,
    items,
    savedEmailPassword,
    userNotFound,
    error,
    passwordError,
    emailError,
    password,
    email,
    checkboxActive,
    userLogin
  } = LoginFunctionality(props);

  const navigate = useNavigate();

  const Item = (props) => {
    return (
      <div>
        <img
          src={props.item.image}
          className="image-styles"
          alt={props.item.name}
          key={props.item.key}
        />
        <div className="text-container">
          <Typography className="about-us-styles">
            {headings.aboutUs}
          </Typography>
          <Typography className="show-case-text-styles">
            {headings.showcase}
          </Typography>
        </div>
      </div>
    );
  };

  if (Cookies.get("jwtToken")) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {isLoading && <CircleLoader />}
      <div className="custom-card-container">
        <div className="sub-custom-card-container">
          <img src={aautiLogo} className="logo-styles" alt="ss" />
          <div className="card-styling">
            <Typography variant="h5" className="typography-text">
              {headings.userName}
            </Typography>
            <Typography variant="body2" className="welcome-text">
              {headings.welcomeText}
            </Typography>
            <Stack className="common-spacing">
              <TextField
                id="outlined-email"
                label="Email"
                variant="outlined"
                size="small"
                value={!checkboxActive ? savedEmailPassword.email : email}
                onChange={handleEmailChange}
                className="textfield-custom-styles"
                required
              />
            </Stack>
            {emailError && (
              <Typography className="error-text-styling">
                {errorMessage.email}
              </Typography>
            )}
            <Stack className="common-spacing">
              <TextField
                id="outlined-password"
                label="Password"
                variant="outlined"
                size="small"
                type="password" // InputLabelProps={{style:{color:"#c8c8c8"}}}
                onChange={handlePasswordChange}
                className="textfield-custom-styles"
                value={!checkboxActive ? savedEmailPassword.password : password}
                required
              />
            </Stack>
            {passwordError && (
              <Typography className="error-text-styling">
                {errorMessage.password}
              </Typography>
            )}
            {error && (
              <Typography className="error-text-styling">
                {errorMessage.emailPassword}
              </Typography>
            )}
            <Stack className="remember-forgot-password-container">
              <Box className="login-MuiBox-root">
                <Checkbox
                  id="eachstudent-checkbox"
                  size="small"
                  className="login-MuiCheckbox-root"
                  checked={checkboxActive}
                  onChange={handleCheckboxChange}
                />
                <Typography id="Remember-me">{headings.rememberMe}</Typography>
              </Box>
              <Typography
                id="Forgot Password"
                className="forgot-password"
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                {headings.forgotPassword}
              </Typography>
            </Stack>
            {userNotFound && (
              <Typography className="error-text-styling">
                {userLogin?.data?.message}
              </Typography>
            )}
            <Stack className="login-signup-container">
              <Button
                variant="contained"
                className="login-button"
                onClick={() => onSubmit()}
              >
                {headings.login}
              </Button>
              <Button variant="outlined" className="sign-up-button">
                {headings.signUp}
              </Button>
            </Stack>
          </div>
        </div>
        <div className="custom-image-card">
          <Carousel
            animation="slide"
            indicatorIconButtonProps={{
              className: "indicator-icon-button-props",
            }}
            activeIndicatorIconButtonProps={{
              className: "active-indicator-icon-button-props",
            }}
            indicatorContainerProps={{
              className: "indicator-container-props",
            }}
            navButtonsWrapperProps={{
              className: "nav-button-wrapper-props",
            }}
          >
            {items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Login;
