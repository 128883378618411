import React from 'react';
import { useSelector } from 'react-redux';
import PropertyTable from './PropertyTable';
import PropertyForm from './PropertyForm';

const MetaRobDashboard = () => {
    const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
        (state) => state.app
      );
    
      return (
        <>
          {showTable && <PropertyTable/>}
          {(showAddForm || showEditForm || showViewForm) && <PropertyForm/>}
        </>
      );

}

export default MetaRobDashboard;
