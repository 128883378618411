import React from "react";
import { useSelector } from "react-redux";
import UserTable from "./UserTable";
import UserForm from "./UserForm";

const User = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

  return (
    <>
      {showTable && <UserTable />}
      {(showAddForm || showEditForm || showViewForm) && <UserForm />}
    </>
  );
};

export default User;
