import React from "react";
import { useSelector } from "react-redux";
import CompanyAdminUserTable from "./CompanyAdminUserTable";
import CompanyAdminUserForm from "./CompanyAdminUserForm";

const CompanyAdminUser = () => {
  const { showEditForm, showAddForm, showViewForm, showTable } = useSelector(
    (state) => state.app
  );

  return (
    <>
      {showTable && <CompanyAdminUserTable />}
      {(showAddForm || showEditForm || showViewForm) && (
        <CompanyAdminUserForm />
      )}
    </>
  );
};

export default CompanyAdminUser;
