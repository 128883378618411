export const sidebarOptions = {
	dashboard: "Dashboard",
	company: "Company",
	// pricing: "Pricing",
	// pricingPlans: "Pricing Plans",
	// linePlans: "Line Plans",
	users: "Users",
	license: "License",
	adminUsers: "Admin Users",
	// campaign: "Campaign",
	// productList: "Product List",
	// configuration: "Configuration",
	// aboutUs: "About Us",
	// contactUs: "Contact Us",
	// faq: "FAQ  ",
	// privacyPolicy: "Privacy Policy",
	// refundPolicy: "Refund Policy",
	// termsAndCondition: "Terms and Condition",
	// pushNotificationKey: "Push Notification Key",
	// newUpdate: "New Update",
	// appDetails: "App Details",
	// casualGames: "Casual Games",
	// games: "Games",
	// matchList: "Match List",
	// completedMatchList: "Completed Match",
	// withdrawMatchList: "Withdraw List",
	// companyConfig: "Company Configuration",
	companyProjects : "Company Projects",
	appConfiguration: "App Configuration",
	metaRob: "MetaRob",
	metaRobData: "MetaRob Data"
};
