import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppConfigContainer } from './Container';
import { useDispatch } from 'react-redux';
import { toggleViewDialog } from '../../redux/reducer/appReducer';
import { Typography, Button } from '@mui/material';
import { appConfigConstants, AppConfigUrlConstants, appConfigLabels } from './Constants';
import TextInputField from '../../common/TextInputField';
import DialogBox from '../../common/DialogBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import "./Style.scss";

const AppConfiguration = () => {

	const {
		tabIndex,
		showTable,
		onChangeTabIndex,
		showEditForm,
		rowData,
		onChangeBackgroundTimeOut,
		onChangeAppVersion,
		onChangeUnderMaintainance,
		onChangeUpTime,
		onClickEditContent,
		onClickUpdate
	} = AppConfigContainer();

	const dispatch = useDispatch();

	const onClickCancel = () => {
		dispatch(toggleViewDialog(true));
	};

	const DataField = ({ label, value }) => (
		<Typography
			variant='body1'
			style={{
				paddingLeft: 10,
				paddingBottom: 10,
				// color: label === appConfigLabels.backgroundTimeOut ? 'black' : '#7f7f7f',
			}}>
			{`${label} : ${value}`}
		</Typography>
	);
	
	const formattedDateTime = dayjs(rowData?.upTime).format('MM/DD/YYYY hh:mm A');

	console.log("upTime:", formattedDateTime); // Output: upTime: 03/25/2024 12:00 AM

	return (
		<div className="global-configurations-custom-card">
			<Typography variant='h6' className='global-configurations-title' style={{ marginBottom: 20, fontSize: 22, color: 'black', fontWeight: 'bolder' }}>
				{appConfigConstants.title}
			</Typography>

			{showTable && (
				<>
					<DataField
						label={appConfigLabels.backgroundTimeOut}
						value={rowData.backgroundTimeOut}
					/>
					<DataField
						label={appConfigLabels.appVersion}
						value={rowData?.appVersion}
					/>
					<DataField label={appConfigLabels.underMaintainance} value={rowData?.underMaintainance} />
					<DataField label={appConfigLabels.upTime} value={formattedDateTime} />
				</>
			)}

			{!showEditForm && (
				<Button
					variant='outlined'
					className='global-form-action-button about-us-button'
					onClick={onClickEditContent}>
					{appConfigConstants.editContent}
				</Button>
			)}

			<DialogBox />

			{showEditForm && (
				<div className='global-configurations-container-styles'>
					<div>
						<TextInputField
							label={appConfigLabels.backgroundTimeOut}
							value={rowData?.backgroundTimeOut}
							onChangeEvent={onChangeBackgroundTimeOut}
						/>
						<TextInputField
							label={appConfigLabels.appVersion}
							value={rowData?.appVersion}
							onChangeEvent={onChangeAppVersion}
						/>
						<TextInputField
							label={appConfigLabels.underMaintainance}
							value={rowData?.underMaintainance}
							onChangeEvent={onChangeUnderMaintainance}
						/>
	
						<LocalizationProvider dateAdapter={AdapterDayjs}>
						
							<DateTimePicker
								label={appConfigLabels.upTime}
								value={dayjs(formattedDateTime)}
								onChange={onChangeUpTime}
							/>
						</LocalizationProvider>


					</div>
				</div>
			)}
			{showEditForm && (
				<div className='update-cancel-container'>
					<Button
						variant='outlined'
						className='global-form-action-button'
						onClick={onClickUpdate}>
						{appConfigConstants.update}
					</Button>
					<Button
						variant='outlined'
						className='global-form-action-button'
						onClick={onClickCancel}>
						{appConfigConstants.cancel}
					</Button>
				</div>
			)}
		</div>
	);

};

export default AppConfiguration;