import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleAddForm,
  toggleEditForm,
  toggleViewDialog,
  toggleViewForm,
  toggleViewTable,
} from "../redux/reducer/appReducer";

const DialogBox = () => {
  const dispatch = useDispatch();
  const { showDialog } = useSelector((state) => state.app);

  const onClickExist = () => {
    dispatch(toggleViewDialog(false));
    dispatch(toggleAddForm(false));
    dispatch(toggleEditForm(false));
    dispatch(toggleViewForm(false));
    dispatch(toggleViewTable(true));
    dispatch(setSelectedRowData({}));
  };

  const onClickDialogClose = () => {
    dispatch(toggleViewDialog(false));
    dispatch(setSelectedRowData({}));
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onClickDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Alert !</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Changes not saved. Sure you want to exit?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className="global-form-action-button"
          onClick={onClickDialogClose}
        >
          No
        </Button>
        <Button
          variant="outlined"
          className="global-form-action-button"
          onClick={onClickExist}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
