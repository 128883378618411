import { useEffect, useState } from 'react';
import { AppConfigUrlConstants } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
		toggleEditForm, 
		toggleLoadingView,
		toggleToastView, 
		toggleViewTable
} from '../../redux/reducer/appReducer';
import { AppConfigService } from './Service';
import { getTableData } from '../tableComponent/tableComponent.slice';
import { CommonContainer } from '../../utils/CommonFunction';
import dayjs from 'dayjs';


export const AppConfigContainer = () => {

		const { showEditForm, showTable, showAddForm } = useSelector((state) => state.app);
		const dispatch = useDispatch();
		const [tabIndex, setTabIndex] = useState('1');
		const { onClickShowTable, onClickEditForm } = CommonContainer();

		const [appConfigData, setAppConfigData] = useState([]);

		const appConfigurationInfo = {
				backgroundTimeOut: 0,
				appVersion: '',
				underMaintainance: false,
				upTime: dayjs(new Date()),
		};

		const [rowData, setRowData] = useState(appConfigurationInfo);

		const getData = async () => {
				dispatch(toggleLoadingView(true));
				const data = await AppConfigService.getAppConfigDataService();
				setRowData({...appConfigurationInfo, ...data?.result});
				dispatch(toggleLoadingView(false));
			};

		useEffect(() => {
				getData()
		}, []);
		
		const onChangeTabIndex = (event, newValue) => {
				setTabIndex(newValue);
		};

		const onChangeBackgroundTimeOut = (event) => {
				setRowData({ ...rowData, backgroundTimeOut: event.target.value });
		};

		const onChangeAppVersion = (event) => {
				setRowData({ ...rowData, appVersion: event.target.value });
		};

		const onChangeUnderMaintainance = (event) => {
				setRowData({ ...rowData, underMaintainance: event.target.value });
		};

		const onChangeUpTime = (event) => {
				setRowData({ ...rowData, upTime: event});
		};
		
		const onClickSave = async () => {
				if ( rowData?.backgroundTimeOut.trim() === ""
				 ) {
					dispatch(
						toggleToastView({
							showToast: true,
							message: "* Please fill all the required fields",
							isError: true,
						})
					);
				} else {
					dispatch(toggleLoadingView(true));
					let response;
					if (showAddForm) {
						response = await AppConfigService.saveAppConfigDataService(rowData);
					} else {
						response = await AppConfigService.updateAppConfigDataService(
							rowData
						);
					}
					if (response.result.length === 0) {
						dispatch(
							toggleToastView({
								showToast: true,
								message: response.message,
								isError: true,
							})
						);
					} else {
						dispatch(
							toggleToastView({
								showToast: true,
								message: response.message,
								isError: false,
							})
						);
						await dispatch(getTableData(AppConfigUrlConstants.getTableDataUrl));
						onClickShowTable();
					}
					dispatch(toggleLoadingView(false));
				}
			};
		
		const onClickAppConfigEditForm = () => {
				onClickEditForm();
		};

		const onClickEditContent = async () => {
				dispatch(toggleEditForm(!showEditForm));
				dispatch(toggleViewTable(false));
		};

		const onClickUpdate = async () => {
				if (rowData?.backgroundTimeOut.trim() === "" ) {
					dispatch(
						toggleToastView({
							showToast: true,
							message: "* Please fill the required fields",
							isError: true,
						})
					);
				} else {
					dispatch(toggleLoadingView(true));
					let response = await AppConfigService.updateAppConfigDataService(rowData);
					if (response.result.length === 0) {
						dispatch(
							toggleToastView({
								showToast: true,
								message: response.message,
								isError: true,
							})
						);
					} else {
						dispatch(
							toggleToastView({
								showToast: true,
								message: response.message,
								isError: false,
							})
						);
						onClickShowTable();
					}
					dispatch(toggleLoadingView(false));
				}
			};

		return {
			 
				tabIndex,
				onChangeTabIndex,
				showEditForm,
				rowData,
				showTable,
				onClickSave,
				showAddForm,
				// onClickAppConfigAddForm,
				onClickAppConfigEditForm,
				onChangeBackgroundTimeOut,
				onChangeAppVersion,
				onChangeUnderMaintainance,
				onChangeUpTime,
				onClickEditContent,
				onClickUpdate
			};
};
