import React, { useState } from 'react';
import TableComponent from '../tableComponent/TableComponent';
import { PropertyContainer } from './Container';
import { propertyUrlConstants } from './Constants';
import { propertyTableColumns } from '../../utils/tableColumns/propertyTableData';
import { Grid } from '@mui/material';
import InputFileUpload from '../../common/InputFileUpload';
import './Style.scss'
import FileDownload from '../../common/FileDownload';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
  } from '@mui/material';

const PropertyTable = () => {
	const propertyColumnFields = propertyTableColumns();
	const {
		showDeleteConfirmation,
		handleDeleteConfirm,
		handleDeleteCancel,
		handleDeleteClick,
		onClickPropertyAddForm,
		onClickPropertyEditForm,
		onClickPropertyViewForm,
		showViewForm,
	} = PropertyContainer();


	const [data, setData] = useState([]);
	const [csvData, setCsvData] = useState([]);

	const onDownload = () => {
		const link = document.createElement("a");
		link.download = `https://aautiversestaging.blob.core.windows.net/metarob/Property_Catalogue_Sample.csv`;
		link.href = "https://aautiversestaging.blob.core.windows.net/metarob/Property_Catalogue_Sample.csv";
		link.click();
	};

	const handleUpload = (event) => {

		debugger;
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = () => {
			const csvData = reader.result;
			const rows = csvData.split("\n");
			const headers = rows[0].split(",");
			const parsedData = [];

			for (let i = 1; i < rows.length; i++) {
				const columns = rows[i].split(",");
				const rowData = {};
				headers.forEach((header, index) => {
					rowData[header.trim()] = columns[index].trim();
				});
				parsedData.push(rowData);
			}

			setCsvData(parsedData);

		};

		reader.readAsText(file);
	};

	return (
		<>
			<Grid
				sx={{
					display: "flex",
				}}
			>

				<InputFileUpload
					label="Upload CSV"
					acceptType="application/csv"
					onChangeEvent={handleUpload}
					isRequired={false}
					isDisabled={showViewForm}
				/>

				<FileDownload
					label="Download Sample CSV"
					onClick={onDownload}
					isDisabled={showViewForm}
				/>

			</Grid>
			<Dialog
				open={showDeleteConfirmation}
				onClose={handleDeleteCancel}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to delete this item?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color='primary' autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<TableComponent
				columnFields={propertyColumnFields}
				url={propertyUrlConstants.getTableDataUrl}
				onClickDelete={handleDeleteClick}
				onClickAddForm={onClickPropertyAddForm}
				onClickEditForm={onClickPropertyEditForm}
				onClickViewForm={onClickPropertyViewForm}
			/>

		</>
	);
};

export default PropertyTable;