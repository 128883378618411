import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Style.scss";
import { paginationNumber } from "./Constants";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
// import { CommonContainer } from "../../utils/CommonFunction";
import { TableContainer } from "./Container";

const TableComponent = ({
  columnFields,
  url,
  onClickDelete,
  onClickAddForm,
  onClickEditForm,
  onClickViewForm,
}) => {
  // const { onClickAddForm, onClickEditForm, onClickViewForm } =
  //   CommonContainer();

  const {
    tableData,
    defaultColDef,
    gridRef,
    rowsPerPage,
    onPageSizeChanged,
    onSelectionChanged,
    onChangeSelectedColumnFields,
    selectedFields,
    columnDefs,
    headerNames,
    selectedRowData,
    onChangeTextFilter,
  } = TableContainer({
    columnFields,
    url,
  });

  return (
    <Grid className="table-con">
      <Grid
        className="tb-operation-header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <FormControl size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              Selected Fields
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedFields}
              onChange={onChangeSelectedColumnFields}
              input={<OutlinedInput label="Selected Fields" />}
              renderValue={(selected) => selected.join(", ")}
              sx={{ mr: 1 }}
              size="small"
              className="tb-head-text-field"
            >
              {headerNames?.map((name, index) => (
                <MenuItem key={index} value={name}>
                  <Checkbox checked={selectedFields.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="outlined-controlled"
            label={"Search"}
            className="tb-head-search-text-field tb-head-text-field"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={onChangeTextFilter}
            // sx={{ width: 300 }}
          />
        </Grid>
        <Grid className="tab-head-act-icons">
          {Object.keys(selectedRowData).length > 0 && (
            <>
              <Tooltip title="View" arrow placement="top">
                <IconButton
                  className="tb-icon-btn-view"
                  onClick={onClickViewForm}
                >
                  <ViewIcon className="tb-act-icons-view" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow placement="top">
                <IconButton className="tb-icon-btn" onClick={onClickEditForm}>
                  <EditIcon className="tb-act-icons" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow placement="top">
                <IconButton className="tb-icon-btn-del" onClick={onClickDelete}>
                  <DeleteIcon className="tb-act-icons-del" />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Add" arrow placement="top">
            <IconButton className="tb-icon-btn-add" onClick={onClickAddForm}>
              <AddIcon className="tb-act-icons-add" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid id="myGrid" className={`ag-theme-alpine grid-table`}>
        <Box className="tb-page-number-container">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Rows</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              label="Rows"
              onChange={onPageSizeChanged}
              sx={{
                height: 30,
              }}
            >
              {paginationNumber.map((eachNumber, index) => (
                <MenuItem value={eachNumber.value} key={index}>
                  {eachNumber.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          rowData={tableData}
          columnDefs={columnDefs}
          resizable={true}
          pagination={true}
          paginationPageSize={rowsPerPage}
          rowSelection="single"
          suppressCellSelection={true}
          suppressDragLeaveHidesColumns={true}
          onSelectionChanged={onSelectionChanged}
        />
      </Grid>
    </Grid>
  );
};

export default TableComponent;
