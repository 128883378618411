import { Badge, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
// import { ReactComponent as Sun } from "../../assets/images/Sun.svg";
// import { ReactComponent as Moon } from "../../assets/images/Moon.svg";
import "./Style.scss";
import {
  NotificationsNone as NotificationsIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { HeaderContainer } from "./Container";
import aautiLogo from "../../assets/images/aauti_logo.png";

const Header = (props) => {
  // const { toggleTheme, appContext } = HeaderContainer();
  const { onClicklogout, toggleSideMenuBar, roleDisplay } =
    HeaderContainer(props);
  return (
    <Grid className="head-container">
      <Grid
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="h5" component={"h5"}>
          <IconButton
            size="large"
            aria-label="menuIcon"
            color="inherit"
            onClick={toggleSideMenuBar}
          >
            <MenuIcon />
          </IconButton>
        </Typography>
        <img
          src={aautiLogo}
          alt="ss"
          style={{
            width: 30,
            height: 30,
            alignSelf: "center",
          }}
        />
        <Typography
          variant="h6"
          component={"h6"}
          sx={{
            textAlign: "center",
            alignSelf: "center",
            ml: 2,
          }}
        >
          {roleDisplay()}
        </Typography>
      </Grid>

      <Grid className="hd-right-container">
        {/* <div className="dark_mode">
          <input
            className="dark_mode_input"
            type="checkbox"
            id="darkmode-toggle"
            onChange={toggleTheme}
            value={appContext?.lightMode === "light"}
          />
          <label className="dark_mode_label" htmlFor="darkmode-toggle">
            <Sun />
            <Moon />
          </label>
        </div> */}

        <IconButton size="large" aria-label="notifications" color="inherit">
          <Badge badgeContent={2} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <div className="profile-container">
          <img
            src="https://www.shutterstock.com/shutterstock/photos/1046605465/display_1500/stock-photo-charminar-hyderabad-india-background-with-copy-space-1046605465.jpg"
            alt="profile"
            className="profile-image"
          />
          {/* <IconButton sx={{ mt: 1 }}>
            <KeyboardArrowDownIcon />
          </IconButton> */}
        </div>
        <IconButton
          size="large"
          aria-label="logouticon"
          color="inherit"
          onClick={onClicklogout}
        >
          <LogoutIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
