export const platformAdminLabels = {
  platform_admin_username_field: "username",
  platform_admin_username: "Username",

  platform_admin_email_field: "email",
  platform_admin_email: "Email",

  platform_admin_role_field: "role",
  platform_admin_role: "Role",

  platform_admin_profile_image: "Profile Image",

  platform_admin_status_field: "active",
  platform_admin_status: "Status",
};
