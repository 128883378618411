import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { companyColumns } from "../../utils/tableColumns/company";
import { CompanyContainer } from "./Container";
import { companyUrlConstants } from "./Constants";

const CompanyTable = () => {
  const companyColumnFields = companyColumns();
  const {
    onClickDelete,
    onClickCompanyAddForm,
    onClickCompanyEditForm,
    onClickCompanyViewForm,
  } = CompanyContainer();

  return (
    <TableComponent
      columnFields={companyColumnFields}
      url={companyUrlConstants.getTableDataUrl}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickCompanyAddForm}
      onClickEditForm={onClickCompanyEditForm}
      onClickViewForm={onClickCompanyViewForm}
    />
  );
};

export default CompanyTable;
