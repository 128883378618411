import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleLoadingView,
} from "../../redux/reducer/appReducer";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTableData } from "./tableComponent.slice";
import { apiStatus } from "../../utils/globalConstant";

export const TableContainer = ({ columnFields, url }) => {
  const dispatch = useDispatch();
  const { status, data: tableData } = useSelector(
    (state) => state.table.tableData
  );
  const { selectedRowData } = useSelector((state) => state.app);

  const gridRef = useRef();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columnDefs, setColumnDefs] = useState(columnFields);

  useEffect(() => {
    if ([apiStatus.success, apiStatus.failed].includes(status)) {
      dispatch(toggleLoadingView(false));
    } else {
      dispatch(toggleLoadingView(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    dispatch(setSelectedRowData({}));
    dispatch(toggleLoadingView(true));
    dispatch(getTableData(url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const onPageSizeChanged = useCallback((event) => {
    let rowCount = event.target.value;

    setRowsPerPage(Number(rowCount));
  }, []);
  const onSelectionChanged = (e) => {
    const selectedRow = e.api.getSelectedRows()[0];
    console.log("selected row--", selectedRow);
    dispatch(setSelectedRowData(selectedRow));
  };

  const onChangeTextFilter = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);
  };

  const headerNames = columnFields.map((each) => each.headerName);

  const [selectedFields, setSelectedFields] = useState(headerNames);

  const onChangeSelectedColumnFields = (event) => {
    const {
      target: { value },
    } = event;

    let columnFieds = columnFields.filter((each) =>
      value.includes(each.headerName)
    );
    setColumnDefs(columnFieds);
    setSelectedFields(typeof value === "string" ? value.split(",") : value);
  };

  return {
    tableData,
    defaultColDef,
    gridRef,
    rowsPerPage,
    onPageSizeChanged,
    onSelectionChanged,
    onChangeSelectedColumnFields,
    selectedFields,
    columnDefs,
    headerNames,
    selectedRowData,
    onChangeTextFilter,
  };
};
