import { Box, Grid, Tab, InputAdornment } from '@mui/material';
import React, { useState } from "react";
import '../../themes/globalStyle.scss';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DialogBox from '../../common/DialogBox';
import TextInputField from '../../common/TextInputField';
import { PropertyContainer } from './Container';
import FormActionButton from '../../common/FormActionButton';
import DropdownField from '../../common/DropdownField';
import { propertyLabels } from '../../assets/labels/propertyData';
import NumericInputField from '../../common/NumericInputField';
import './Style.scss'
import { propertyConstants } from './Constants';


const PropertyForm = () => {
	const {
		onChangePropertyId,
		onChangePropertyName,
		onChangePropertyType,
		onChangeFacing,
		onChangeCapacity,
		onChangeAminities,
		onChangeDescription,
		onChangePrice,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		showAddForm,
		rowData,
		onClickSave,
		onClickReset,
		onChangeCompany,
		companyData,
		propertyTypeData,
		propertyFacingValues,

	} = PropertyContainer();


	const [data, setData] = useState([])

	return (
		<Grid className="global-form">
			<Box
				className="global-form-container"
				sx={{
					typography: "body1",
					height: "95%",
				}}
			>
				<TabContext value={tabIndex}>
					<Box className="global-form-tab-header">
						<TabList
							onChange={onChangeTabIndex}
							aria-label="lab API tabs example"
						>
							<Tab label="Property Form" value="1" />
						</TabList>
					</Box>
					<TabPanel value="1" className="global-form-fields-container">

						<TextInputField
							label="companyId"
							value={rowData?.companyId}
							isRequired={true}
							isDisabled={showViewForm || showEditForm ||  showAddForm}
						/>
						<DropdownField
							label={propertyLabels.companyId}
							value={rowData?.companyId}
							onChangeEvent={onChangeCompany}
							data={companyData}
							isDisabled={showViewForm || showEditForm}
							isRequired={true}
						/>
						<TextInputField
							label={propertyLabels.property_id}
							value={rowData?.propertyID}
							onChangeEvent={onChangePropertyId}
							isRequired={true}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={propertyLabels.property_name}
							value={rowData?.propertyName}
							onChangeEvent={onChangePropertyName}
							isRequired={true}
							isDisabled={showViewForm}
						/>

						<DropdownField
							label={propertyLabels.property_type}
							value={rowData?.propertyType}
							onChangeEvent={onChangePropertyType}
							data={propertyTypeData}
							isRequired={true}
							isDisabled={showViewForm}
						/>

						<DropdownField
							label={propertyLabels.facing}
							value={rowData?.facing}
							onChangeEvent={onChangeFacing}
							isRequired={true}
							isDisabled={showViewForm}
							data={propertyFacingValues}
						/>

						<TextInputField
							label={propertyLabels.capacity}
							value={rowData?.capacity}
							onChangeEvent={onChangeCapacity}
							isRequired={true}
							isDisabled={showViewForm}
						/>

						<TextInputField
							label={propertyLabels.aminities}
							value={rowData?.aminities}
							onChangeEvent={onChangeAminities}
							isRequired={true}
							isDisabled={showViewForm}
						/>
						<NumericInputField
							label={propertyLabels.price}
							value={rowData?.price}
							onChangeEvent={onChangePrice}
							multiline={true}
							showLeftIcon={true}
							leftComponent={
								<InputAdornment position="start">Rs</InputAdornment>
							}
							isDisabled={showViewForm}
							isRequired={true}
						/>

						<TextInputField
							label={propertyLabels.description}
							value={rowData?.description}
							onChangeEvent={onChangeDescription}
							isRequired={true}
							isDisabled={showViewForm}
							descriptionStyling={{ margin: "0px", width: "600px" }}
							size="large"
							multiline={true}
							rows={3}
						/>

					</TabPanel>
					<FormActionButton
						onClickSave={() => {
							onClickSave();
						}}
						onClickReset={onClickReset}
					/>
				</TabContext>
				<DialogBox />


			</Box>


		</Grid>
	);
};
export default PropertyForm;