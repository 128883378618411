import { TextField } from "@mui/material";
import React, { useState } from "react";
import "../themes/globalStyle.scss";
import { checkEmailValidation } from "../utils/helper";

const TextInputField = ({
  label,
  value,
  onChangeEvent,
  multiline = false,
  showLeftIcon = false,
  showRightIcon = false,
  leftComponent,
  rightComponent,
  isRequired = false,
  isMail = false,
  isDisabled = false,
  descriptionStyling ={},
  size = "small",
  rows = 0
}) => {
  const [showError, setShowError] = useState(false);
  const onBlurEvent = (event) => {
    if (event.target.value === "" && isRequired) {
      setShowError(true);
    } else if (isMail) {
      if (checkEmailValidation(event.target.value)) {
        setShowError(false);
      } else {
        setShowError(true);
      }
    } else {
      setShowError(false);
    }
  };

  return (
    <TextField
      id="outlined-controlled"
      label={label}
      value={value}
      size={size}
      multiline={multiline}
      required={isRequired}
      error={showError}
      disabled={isDisabled}
      InputProps={{
        startAdornment: showLeftIcon && leftComponent,
        endAdornment: showRightIcon && rightComponent,
      }}
      onChange={onChangeEvent}
      onBlur={onBlurEvent}
      className="global-form-text-input-field"
      style={descriptionStyling}
      rows={rows}
    />
  );
};

export default TextInputField;
