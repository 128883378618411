import { useEffect, useState } from "react";
import { companyProjectsUrlConstants } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleLoadingView,
  toggleToastView,
} from "../../redux/reducer/appReducer";
import { companyProjectsService } from "./Service";
import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";
import {
  checkNumericExpression,
  deleteFiles,
  deleteImage,
  fileUpload,
  imageUpload,
} from "../../utils/helper";
import { fileUploadPaths } from "../../utils/globalConstant";

export const CompanyProjectsContainer = () => {
  const { showEditForm, showAddForm, showViewForm, selectedRowData } =
	useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
	CommonContainer();
  const [companyData, setCompanyData] = useState([]);
  

  const companyConfigInfo = {
	companyId: "",
	name: "",
	shortDescription: "",
	description: "",
	photonId: "",
	appIdRealtime: "",
	appIdFusion: "",
	appIdChat: "",
	appIdVoice: "",
	appVersion: "",
	userNameServer: false,
	fixedRegion: "",
	server: "",
	port: 0,
	proxyServer: "",
	protocol: "",
	enableProtocalFallback: false,
	authMode: 0,
	enableLobbyStatistics: false,
	networkLogging: 0,
	previewImage: "",
	showCaseImages: [],
	android: {
	  sceneGuid: "",
	  agoraId: "",
	  json: [],
	  bundle: [],
	  hash: [],
	},
	ios: {
	  sceneGuid: "",
	  agoraId: "",
	  json: [],
	  bundle: [],
	  hash: [],
	},
	windows: {
	  sceneGuid: "",
	  agoraId: "",
	  json: [],
	  bundle: [],
	  hash: [],
	},
	webgl: {
	  sceneGuid: "",
	  agoraId: "",
	  json: [],
	  bundle: [],
	  hash: [],
	},
	mac: {
	  sceneGuid: "",
	  agoraId: "",
	  json: [],
	  bundle: [],
	  hash: [],
	},
  };

  const [rowData, setRowData] = useState(companyConfigInfo);
  const [filesToRemove, setFilestoRemove] = useState([]);
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [previewImageToRemove, setPreviewImageToRemove] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [fileTypeToRemove, setFileTypeToRemove] = useState(null);
const [fileIndexToRemove, setFileIndexToRemove] = useState(null);


  const [resetData, setResetData] = useState({});
  const getSelectedRowData = async () => {
	dispatch(toggleLoadingView(true));
	const data =
	  await companyProjectsService.getSelectedCompanyProjectsDataService(
		selectedRowData?._id
	  );
	setRowData({ ...companyConfigInfo, ...data.result });
	setResetData({ ...companyConfigInfo, ...data.result });
	dispatch(toggleLoadingView(false));
  };

  const getCompanyData = async () => {
	const data = await companyProjectsService.getCompanyNamesService();
	setCompanyData(data.result);
	showAddForm && setRowData({ ...rowData, companyId: data.result[0]?.id });
  };

  useEffect(() => {
	if (showEditForm || showViewForm) {
	  getSelectedRowData();
	}
	if (showEditForm || showViewForm || showAddForm) {
	  getCompanyData();
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTabIndex = (event, newValue) => {
	setTabIndex(newValue);
  };

  const onChangeCompanyName = (event) => {
	setRowData({ ...rowData, name: event.target.value });
  };

  const onChangeCompany = (event) => {
	const newCompanyId = event.target.value;
	if (rowData.companyId !== newCompanyId) {
	  setRowData({
		...companyConfigInfo,
		companyId: newCompanyId,
		name: "",
		shortDescription: "",
		description: "",
		photonId: "",
		appIdRealtime: "",
		appIdFusion: "",
		appIdChat: "",
		appIdVoice: "",
		appVersion: "",
		userNameServer: false,
		fixedRegion: "",
		server: "",
		port: 0,
		proxyServer: "",
		protocol: "",
		enableProtocalFallback: false,
		authMode: 0,
		enableLobbyStatistics: false,
		networkLogging: 0,
		previewImage: "",
		showCaseImages: [],
		android: {
		  sceneGuid: "",
		  agoraId: "",
		  json: [],
		  bundle: [],
		  hash: [],
		},
		ios: {
		  sceneGuid: "",
		  agoraId: "",
		  json: [],
		  bundle: [],
		  hash: [],
		},
		windows: {
		  sceneGuid: "",
		  agoraId: "",
		  json: [],
		  bundle: [],
		  hash: [],
		},
		webgl: {
		  sceneGuid: "",
		  agoraId: "",
		  json: [],
		  bundle: [],
		  hash: [],
		},
		mac: {
		  sceneGuid: "",
		  agoraId: "",
		  json: [],
		  bundle: [],
		  hash: [],
		},
	  });
	} else {
	  setRowData({ ...rowData, companyId: newCompanyId });
	}
  };
  const onChangeShortDescription = (event) => {
	setRowData({ ...rowData, shortDescription: event.target.value });
  };
  const onChangeDescription = (event) => {
	setRowData({ ...rowData, description: event.target.value });
  };

  const onChangeSceneGuid = (event, platform) => {
	setRowData({
	  ...rowData,
	  [platform]: {
		...rowData[platform],
		sceneGuid: event.target.value,
	  },
	});
  };

  const onChangePhotonSettings = (event, fieldName) => {
	setRowData({
	  ...rowData,
	  [fieldName]: event.target.value,
	});
  };

  const onChangeAgoraId = (event, platform) => {
	setRowData({
	  ...rowData,
	  [platform]: {
		...rowData[platform],
		agoraId: event.target.value,
	  },
	});
  };

  const onChangePhotonId = (event) => {
	setRowData({ ...rowData, photonId: event.target.value });
  };

  const handleNumericInput = (event, field) => {
	if (checkNumericExpression(event.target.value)) {
	  const value = parseInt(event.target.value);
	  setRowData({ ...rowData, [field]: value.toString() });
	} else if (event.target.value === "") {
	  setRowData({ ...rowData, [field]: 0 });
	}
  };

  const onHandleCheckBox = (event, fieldName) => {
	setRowData({ ...rowData, [fieldName]: event.target.checked });
  };
  const onchangeJSONFiles = async (type, event) => {
	dispatch(toggleLoadingView(true));

	let filePath;
	switch (type) {
	  case "androidJSON":
	  case "androidHash":
	  case "androidBundle":
		filePath = fileUploadPaths.androidPath(rowData?.companyId);
		break;
	  case "iosJSON":
	  case "iosHash":
	  case "iosBundle":
		filePath = fileUploadPaths.iosPath(rowData?.companyId);
		break;
	  case "windowsJSON":
	  case "windowsHash":
	  case "windowsBundle":
		filePath = fileUploadPaths.windowsPath(rowData?.companyId);
		break;
	  case "webglJSON":
	  case "webglHash":
	  case "webglBundle":
		filePath = fileUploadPaths.webglPath(rowData?.companyId);
		break;
	  case "macJSON":
	  case "macHash":
	  case "macBundle":
		filePath = fileUploadPaths.macPath(rowData?.companyId);
		break;
	  default:
		break;
	}
	const res = await fileUpload(event, filePath);
	const fileName = res.result.file;
	const isFileNameExists = (fileName) => {
	  switch (type) {
		case "androidJSON":
		  return rowData.android.json.find((item) => item === fileName);
		case "androidHash":
		  return rowData.android.hash.find((item) => item === fileName);
		case "androidBundle":
		  return rowData.android.bundle.find((item) => item === fileName);
		case "iosJSON":
		  return rowData.ios.json.find((item) => item === fileName);
		case "iosHash":
		  return rowData.ios.hash.find((item) => item === fileName);
		case "iosBundle":
		  return rowData.ios.bundle.find((item) => item === fileName);
		case "windowsJSON":
		  return rowData.windows.json.find((item) => item === fileName);
		case "windowsHash":
		  return rowData.windows.hash.find((item) => item === fileName);
		case "windowsBundle":
		  return rowData.windows.bundle.find((item) => item === fileName);
		case "webglJSON":
		  return rowData.webgl.json.find((item) => item === fileName);
		case "webglHash":
		  return rowData.webgl.hash.find((item) => item === fileName);
		case "webglBundle":
		  return rowData.webgl.bundle.find((item) => item === fileName);
		case "macJSON":
		  return rowData.mac.json.find((item) => item === fileName);
		case "macHash":
		  return rowData.mac.hash.find((item) => item === fileName);
		case "macBundle":
		  return rowData.mac.bundle.find((item) => item === fileName);
		default:
		  break;
	  }
	};

	const isFileExists = isFileNameExists(fileName);

	if (isFileExists) {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message:
			"File with the same name already exists. Upload a different file.",
		  isError: true,
		})
	  );
	} else {
	  if (res.error) {
		dispatch(
		  toggleToastView({
			showToast: true,
			message: "Please upload again",
			isError: true,
		  })
		);
	  } else {
		switch (type) {
		  case "androidJSON":
			setRowData({
			  ...rowData,
			  android: {
				...rowData.android,
				json: [...rowData.android.json, res.result.file],
			  },
			});
			break;
		  case "androidHash":
			setRowData({
			  ...rowData,
			  android: {
				...rowData.android,
				hash: [...rowData.android.hash, res.result.file],
			  },
			});
			break;
		  case "androidBundle":
			setRowData({
			  ...rowData,
			  android: {
				...rowData.android,
				bundle: [...rowData.android.bundle, res.result.file],
			  },
			});
			break;
		  case "iosJSON":
			setRowData({
			  ...rowData,
			  ios: {
				...rowData.ios,
				json: [...rowData.ios.json, res.result.file],
			  },
			});
			break;
		  case "iosHash":
			setRowData({
			  ...rowData,
			  ios: {
				...rowData.ios,
				hash: [...rowData.ios.hash, res.result.file],
			  },
			});
			break;
		  case "iosBundle":
			setRowData({
			  ...rowData,
			  ios: {
				...rowData.ios,
				bundle: [...rowData.ios.bundle, res.result.file],
			  },
			});
			break;
		  case "windowsJSON":
			setRowData({
			  ...rowData,
			  windows: {
				...rowData.windows,
				json: [...rowData.windows.json, res.result.file],
			  },
			});
			break;
		  case "windowsHash":
			setRowData({
			  ...rowData,
			  windows: {
				...rowData.windows,
				hash: [...rowData.windows.hash, res.result.file],
			  },
			});
			break;
		  case "windowsBundle":
			setRowData({
			  ...rowData,
			  windows: {
				...rowData.windows,
				bundle: [...rowData.windows.bundle, res.result.file],
			  },
			});
			break;
		  case "webglJSON":
			setRowData({
			  ...rowData,
			  webgl: {
				...rowData.webgl,
				json: [...rowData.webgl.json, res.result.file],
			  },
			});
			break;
		  case "webglHash":
			setRowData({
			  ...rowData,
			  webgl: {
				...rowData.webgl,
				hash: [...rowData.webgl.hash, res.result.file],
			  },
			});
			break;
		  case "webglBundle":
			setRowData({
			  ...rowData,
			  webgl: {
				...rowData.webgl,
				bundle: [...rowData.webgl.bundle, res.result.file],
			  },
			});
			break;
		  case "macJSON":
			setRowData({
			  ...rowData,
			  mac: {
				...rowData.mac,
				json: [...rowData.mac.json, res.result.file],
			  },
			});
			break;
		  case "macHash":
			setRowData({
			  ...rowData,
			  mac: {
				...rowData.mac,
				hash: [...rowData.mac.hash, res.result.file],
			  },
			});
			break;
		  case "macBundle":
			setRowData({
			  ...rowData,
			  mac: {
				...rowData.mac,
				bundle: [...rowData.mac.bundle, res.result.file],
			  },
			});
			break;
		  default:
			break;
		}
	  }
	}
	dispatch(toggleLoadingView(false));
  };

  const onchangeImage = async (event) => {
	dispatch(toggleLoadingView(true));
	const res = await imageUpload(event);
	if (res.error) {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: "Please upload again",
		  isError: true,
		})
	  );
	} else {
	  setRowData({ ...rowData, previewImage: res?.result?.image });
	}
	dispatch(toggleLoadingView(false));
  };

  const onchangeMultipleImages = async (event) => {
	dispatch(toggleLoadingView(true));
	const res = await imageUpload(event);
	if (res.error) {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: "Please upload again",
		  isError: true,
		})
	  );
	} else {
	  const isFileExists = rowData?.showCaseImages.some(
		(item) => item === res.result.image
	  );

	  if (isFileExists) {
		dispatch(
		  toggleToastView({
			showToast: true,
			message: "Same image already exists. Upload a different image",
			isError: true,
		  })
		);
	  } else {
		setRowData({
		  ...rowData,
		  showCaseImages: [...rowData.showCaseImages, res.result.image],
		});
	  }
	}
	dispatch(toggleLoadingView(false));
  };

  const deleteShowCaseImages = async () => {
	imagesToRemove.map(async (removeImages) => {
	  await deleteImage(removeImages);
	});
  };

  const deletePreviewImage = async () => {
	previewImageToRemove.map(async (removePreviewImage) => {
	  await deleteImage(removePreviewImage);
	});
  };

  const deleteRemovedFiles = async () => {
	filesToRemove.map(async (removedFile) => {
	  await deleteFiles(removedFile);
	});

	//  if (deleteResponse.error) {
	//    dispatch(
	//      toggleToastView({
	//        showToast: true,
	//        message: deleteResponse.message,
	//        isError: true,
	//      })
	//    );
	//  } else {
	//    dispatch(
	//      toggleToastView({
	//        showToast: true,
	//        message: deleteResponse.message,
	//        isError: false,
	//      })
	//    );}
  };

  const onClickSave = async () => {
	if (rowData?.companyId.trim() === "" || rowData?.name.trim() === "") {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: "* Please fill the required fields",
		  isError: true,
		})
	  );
	} else {
	  dispatch(toggleLoadingView(true));
	  let response;
	  if (showAddForm) {
		response = await companyProjectsService.saveCompanyProjectsDataService(
		  rowData
		);
	  } else {
		await deletePreviewImage();
		await deleteRemovedFiles();
		await deleteShowCaseImages();
		response =
		  await companyProjectsService.updateCompanyProjectsDataService(
			rowData
		  );
	  }
	  if (response.result.length === 0) {
		dispatch(
		  toggleToastView({
			showToast: true,
			message: response.message,
			isError: true,
		  })
		);
	  } else {
		dispatch(
		  toggleToastView({
			showToast: true,
			message: response.message,
			isError: false,
		  })
		);
		await dispatch(
		  getTableData(companyProjectsUrlConstants.getTableDataUrl)
		);
		onClickShowTable();
	  }
	  dispatch(toggleLoadingView(false));
	}
  };
  const onClickReset = () => {
	setRowData(resetData);
	setPreviewImageToRemove([]);
	setImagesToRemove([]);
	setFilestoRemove([]);
  };

  const onClickCompanyConfigAddForm = () => {
	onClickAddForm();
  };
  const onClickCompanyConfigEditForm = () => {
	onClickEditForm();
  };
  const onClickCompanyConfigViewForm = () => {
	onClickViewForm();
  };

  const onClickDelete = async () => {
	dispatch(toggleLoadingView(true));
	const response =
	  await companyProjectsService.deleteCompanyProjectsDataService(
		selectedRowData?._id
	  );
	dispatch(setSelectedRowData({}));
	if (response.error) {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: response.message,
		  isError: true,
		})
	  );
	} else {
	  dispatch(
		toggleToastView({
		  showToast: true,
		  message: response.message,
		  isError: false,
		})
	  );
	  await dispatch(getTableData(companyProjectsUrlConstants.getTableDataUrl));
	  dispatch(toggleLoadingView(false));
	}
  };
  const onRemovePreviewImage = () => {
	const previewImage = rowData.previewImage;
	previewImageToRemove.push({ image: previewImage });
	setPreviewImageToRemove(previewImageToRemove);

	setRowData({
	  ...rowData,
	  previewImage: "",
	});
  };

  const onRemoveShowCaseImages = (platform, index) => {
	const updatedRowData = {
	  ...rowData,
	  showCaseImages: [...rowData.showCaseImages],
	};

	if (index < updatedRowData[platform]?.length) {
	  updatedRowData[platform] = updatedRowData[platform].filter(
		(file, fileIndex) => {
		  if (fileIndex === index) {
			imagesToRemove?.push({ image: file });
		  }
		  return fileIndex !== index;
		}
	  );
	}
	setRowData(updatedRowData);
	setImagesToRemove(imagesToRemove);
  };

  const onRemoveFile = (type, index) => {
	const removeFile = (platform, fileType) => {
	  const files = [...rowData[platform][fileType]];
	  if (index < files.length) {
		const fileToRemove = files[index];

		const updatedFiles = files.filter((file) => file !== fileToRemove);
		setRowData({
		  ...rowData,
		  [platform]: {
			...rowData[platform],
			[fileType]: updatedFiles,
		  },
		});

		setFilestoRemove([
		  ...filesToRemove,
		  {
			companyId: rowData?.companyId,
			device: platform,
			fileType,
			file: fileToRemove,
		  },
		]);
	  }
	};



	switch (type) {
	  case "androidJSON":
		removeFile("android", "json");
		break;
	  case "androidHash":
		removeFile("android", "hash");
		break;
	  case "androidBundle":
		removeFile("android", "bundle");
		break;
	  case "iosJSON":
		removeFile("ios", "json");
		break;
	  case "iosHash":
		removeFile("ios", "hash");
		break;
	  case "iosBundle":
		removeFile("ios", "bundle");
		break;
	  case "windowsJSON":
		removeFile("windows", "json");
		break;
	  case "windowsHash":
		removeFile("windows", "hash");
		break;
	  case "windowsBundle":
		removeFile("windows", "bundle");
		break;
	  case "webglJSON":
		removeFile("webgl", "json");
		break;
	  case "webglHash":
		removeFile("webgl", "hash");
		break;
	  case "webglBundle":
		removeFile("webgl", "bundle");
		break;
	  case "macJSON":
		removeFile("mac", "json");
		break;
	  case "macHash":
		removeFile("mac", "hash");
		break;
	  case "macBundle":
		removeFile("mac", "bundle");
		break;
	  default:
		break;
	}
  };

  const handleRemoveClick = (type, index) => {
	setConfirmationDialogOpen(true);
	setFileTypeToRemove(type);
    setFileIndexToRemove(index);
  };

const handleRemoveConfirm = () => {
	onRemoveFile(fileTypeToRemove, fileIndexToRemove);
	setConfirmationDialogOpen(false);
  };

  const handleRemoveCancel = () => {
	setConfirmationDialogOpen(false);
  };

  const handleDeleteClick = () => {
	setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
	onClickDelete();
	setShowDeleteConfirmation(false);
  };

  const handleDeleteCancel = () => {
	setShowDeleteConfirmation(false);
  };



  return {
	handleRemoveClick,
	handleRemoveConfirm,
	handleRemoveCancel,
	confirmationDialogOpen,
	onChangeCompany,
	tabIndex,
	onChangeTabIndex,
	showViewForm,
	showEditForm,
	onClickSave,
	onClickReset,
	rowData,
	onClickDelete,
	companyData,
	onClickCompanyConfigAddForm,
	onClickCompanyConfigEditForm,
	onClickCompanyConfigViewForm,
	onChangeShortDescription,
	onChangeDescription,
	onchangeImage,
	onChangeCompanyName,
	onchangeMultipleImages,
	onchangeJSONFiles,
	onChangeSceneGuid,
	onChangeAgoraId,
	onRemoveFile,
	onRemoveShowCaseImages,
	onRemovePreviewImage,
	onChangePhotonSettings,
	handleNumericInput,
	onHandleCheckBox,
	handleDeleteClick,
	handleDeleteConfirm,
	handleDeleteCancel,
	showDeleteConfirmation,
	onChangePhotonId,
  };
};
