import React from "react";
import { Button, Tooltip } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Add as AddIcon } from '@mui/icons-material';

const FileDownload = ({
  label,
  onClick,
  isRequired = false,
  isDisabled = false,
  initialFileUpload = false
}) => {
  return (
	<>
	  {initialFileUpload ?
		<Tooltip title={`Add ${label}`} arrow placement="top">
		  <Button
			component="label"
			variant="contained"
			startIcon={<AddIcon className="tb-act-icons-add" />}
			sx={{ m: 1, background: "#00000099", height: 70, borderRadius: 35, display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center" }}
		  >
			{`${isRequired ? "*" : ""}`}
			<input
			  hidden
			  type="file"
			  disabled={isDisabled}
			/>
		  </Button>
		</Tooltip> :
		<Button
		  variant="contained"
		  startIcon={<CloudDownloadIcon />}
		  onClick={onClick}
		  sx={{ m: 1, background: "#00000099", height: 100 }}
		>
		  {label} {`${isRequired ? "*" : ""}`}
		</Button>}
	</>
  );
};

export default FileDownload;
