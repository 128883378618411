import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { userColumns } from "../../utils/tableColumns/user";
import { CompanyAdminUserContainer } from "./Container";
import secureLocalStorage from "react-secure-storage";
import { companyAdminUrlConstants } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";

const CompanyAdminUserTable = () => {
  const userColumnFields = userColumns();
  const {
    onClickDelete,
    onClickUserAddForm,
    onClickUserEditForm,
    onClickUserViewForm,
  } = CompanyAdminUserContainer();

  return (
    <TableComponent
      columnFields={userColumnFields}
      url={`${
        companyAdminUrlConstants.getTableDataUrl
      }/${secureLocalStorage.getItem(storageConstants.companyId)}`}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickUserAddForm}
      onClickEditForm={onClickUserEditForm}
      onClickViewForm={onClickUserViewForm}
    />
  );
};

export default CompanyAdminUserTable;
