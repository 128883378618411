import { Box, Grid, InputAdornment, Tab, Typography } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import { CompanyContainer } from "./Container";
import FormActionButton from "../../common/FormActionButton";
import { companyLabels } from "../../assets/labels/company";
import DropdownField from "../../common/DropdownField";
import NumericInputField from "../../common/NumericInputField";
import { companyConstants } from "./Constants";
import InputFileUpload from "../../common/InputFileUpload";

const CompanyForm = () => {
  const {
    tabIndex,
    onChangeTabIndex,
    onChangeRole,
    onChangeCompanyName,
    onChangeEmail,
    onChangePaymentType,
    onChangeSubscriptionStatus,
    showViewForm,
    showEditForm,
    onClickSave,
    onClickReset,
    rowData,
    paymentTypeData,
    onChangeProfileImage,
    onChangeStatus,
  } = CompanyContainer();

  return (
    <Grid className="global-form">
      <Box
        className="global-form-container"
        sx={{
          typography: "body1",
          height: "95%",
        }}
      >
        <TabContext value={tabIndex}>
          <Box className="global-form-tab-header">
            <TabList
              onChange={onChangeTabIndex}
              aria-label="lab API tabs example"
            >
              <Tab label="Company Form" value="1" />
            </TabList>
          </Box>
          <TabPanel value="1" className="global-form-fields-container">
            <TextInputField
              label={companyLabels.company_name}
              value={rowData?.companyName}
              onChangeEvent={onChangeCompanyName}
              isRequired={true}
              isDisabled={showViewForm}
            />
            <TextInputField
              label={companyLabels.email}
              value={rowData?.email}
              onChangeEvent={onChangeEmail}
              isRequired={true}
              isMail={true}
              isDisabled={showViewForm || showEditForm}
            />
            <DropdownField
              label={companyLabels.company_role}
              value={rowData?.role}
              onChangeEvent={onChangeRole}
              data={companyConstants.companyRoles}
              isDisabled={showViewForm}
            />
            <DropdownField
              label={companyLabels.payment_type}
              value={rowData?.paymentType}
              onChangeEvent={onChangePaymentType}
              data={paymentTypeData}
              isDisabled={showViewForm}
            />
            <NumericInputField
              label={companyLabels.user_limit}
              value={rowData?.userLimit}
              // onChangeEvent={onChangeUserLimit}
              isDisabled={true}
            />
            <NumericInputField
              label={companyLabels.company_plan_duration}
              value={rowData?.duration}
              // onChangeEvent={onChangeUserLimit}
              isDisabled={true}
            />
            <NumericInputField
              label={companyLabels.price}
              value={rowData?.price}
              // onChangeEvent={onChangePrice}
              showLeftIcon={true}
              leftComponent={
                <InputAdornment position="start">$</InputAdornment>
              }
              isDisabled={true}
            />

            <DropdownField
              label={companyLabels.subscription_status}
              value={rowData?.subscriptionStatus}
              onChangeEvent={onChangeSubscriptionStatus}
              data={companyConstants.subscriptionStatusValues}
              isDisabled={showViewForm}
            />
            <DropdownField
              label={companyLabels.company_status}
              value={rowData?.active}
              onChangeEvent={onChangeStatus}
              data={companyConstants.status}
              isDisabled={showViewForm}
              isRequired={false}
            />
            <Grid
              sx={{
                display: "flex",
              }}
            >
              <InputFileUpload
                label={companyLabels.company_profile_image}
                onChangeEvent={onChangeProfileImage}
                isRequired={false}
                isDisabled={showViewForm}
              />

              {rowData?.image ? (
                <img
                  src={rowData?.image}
                  alt="profile_image"
                  style={{
                    width: 95,
                    height: 95,
                    margin: 8,
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#00000099",
                    fontSize: 12,
                  }}
                >
                  No image uploaded
                </Typography>
              )}
            </Grid>
          </TabPanel>
          <FormActionButton
            onClickSave={onClickSave}
            onClickReset={onClickReset}
          />
        </TabContext>
        <DialogBox />
      </Box>
    </Grid>
  );
};

export default CompanyForm;
