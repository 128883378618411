import { useState } from 'react';
import { toggleEditForm, toggleLoadingView, toggleToastView, toggleViewTable } from '../../redux/reducer/appReducer';
import { useDispatch, useSelector } from 'react-redux';
import { newUpdateConfigurationService } from './Service';
import { CommonContainer } from '../../utils/CommonFunction';
import dayjs from 'dayjs';

export const NewUpdateContainer = () => {
  const initialData = {
    versionName : "",
    updatedDate :  dayjs(new Date()),
    versionCode : "",
    description : "",
    forceUpdate : "",
    reviewModeVersion : -1,
    downloadLink : ""
  };
  const dispatch = useDispatch()
  const [data, setData] = useState(initialData);
  const { showEditForm } = useSelector((state) => state.app);
  const { onClickShowTable} = CommonContainer();

  
  const getData = async () => {
    dispatch(toggleLoadingView(true));
    const data = await newUpdateConfigurationService.getNewConfigurationService();
    setData(data?.result);
    dispatch(toggleLoadingView(false));
  };
  const onClickEditContent =async () => {
    dispatch(toggleEditForm(!showEditForm));
    await getData();
    dispatch(toggleViewTable(false));
  };
  const onChangeversionName = (event) => {
    setData({ ...data, versionName: event.target.value });
  };

  const onChangeUpdatedDate = (event) => {
    setData({ ...data, updatedDate : event });
  };

  const onChangeversionCode = (event) => {
    setData({ ...data, versionCode: event.target.value });
  };

  const onChangeDescription = (event) => {
    setData({ ...data, description: event });
  };
  
  const onChangeForceUpdate = (event) => {
    setData({ ...data, forceUpdate: event.target.value });
  };
  const onChangereviewModeVersion = (event) => {
    setData({ ...data, reviewModeVersion: event.target.value });
  };
  const onChangeDownloadLink = (event) => {
    setData({ ...data, downloadLink: event.target.value });
  };
  
  const onClickUpdate = async () => {
    if (data?.versionName.trim() === "" || data?.versionCode.trim() === "" || data?.description.trim() === "" || data?.updatedDate === "" 
        || data?.forceUpdate.trim() === "" || data?.reviewModeVersion === "") {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "* Please fill the required fields",
          isError: true,
        })
      );
    } else {
      dispatch(toggleLoadingView(true));
      let response = await newUpdateConfigurationService.updateNewConfigurationService(data);
      if (response.result.length === 0) {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: true,
          })
        );
      } else {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: false,
          })
        );
        onClickShowTable();
      }
      dispatch(toggleLoadingView(false));
    }
  };

  return {
    data,
    showEditForm,
    onChangeversionCode,
    onChangeUpdatedDate,
    onChangeversionName,
    onChangeDescription,
    onClickUpdate,
    onChangeForceUpdate,
    onChangereviewModeVersion,
    onClickEditContent,
    onChangeDownloadLink
  };
};
