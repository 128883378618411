export const propertyLabels = {

  companyId_filed: "companyName",
  companyId: "Company",

  property_id_field: "propertyID",
  property_id: "Property ID",

  property_name_field: 'propertyName',
  property_name: 'Property Name',

  property_type_field: "propertyType",
  property_type: "Property Type",

  facing_field: "facing",
  facing: "Facing",

  capacity_field: "capacity",
  capacity: "Capacity",

  aminities_field: "aminities",
  aminities: "Aminities",

  description_field: "description",
  description: "Description",

  price_field: "price",
  price: "Price"


};
