import React from "react";
import "./Style.scss";
import LayersIcon from "@mui/icons-material/Layers";
import BasicTable from "./BasicTable";
import { Typography } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import AnalyticsFunctionality from "../analytics/Container";
import { analytics, pricingPlan } from "./Constants";
import { storageConstants } from "../../utils/globalConstant";
import { Tooltip, Label, XAxis, YAxis, Legend, Bar, BarChart, LabelList } from 'recharts';
import DropdownField from "../../common/DropdownField";

export default function Analytics(props) {
  const { userCount, companyUsers, endUsers, pricingPlans, barChartPricingPlans, chartType, handleChartTypeChange} =
    AnalyticsFunctionality(props);

    const filterDataByDay = (data) => {
      const currentDate = new Date();
      const lastWeekDate = new Date();
      lastWeekDate.setDate(lastWeekDate.getDate() - 7);
      data?.sort((a, b) => new Date(a._id) - new Date(b._id));
      return data?.filter(item => {
        const createdAtDate = new Date(item._id);
          return createdAtDate >= lastWeekDate && createdAtDate <= currentDate;
        });
    };

  const filterDataByMonth = (data) => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const threeMonthsAgoStart = new Date(currentYear, currentMonth - 3, 1);
     
      data?.sort((a, b) => new Date(a._id) - new Date(b._id));
      return data?.filter(item => {
        const createdAtDate = new Date(item._id);
           return createdAtDate >= currentMonthStart ||  createdAtDate >= threeMonthsAgoStart;
        });
  };

  const filteredData = (chartType === 'day') ? filterDataByDay(barChartPricingPlans) : filterDataByMonth(barChartPricingPlans);
  
  const TotalUserCard = ({ title, count }) => {
    return (
      <div className="total-user-custom-card">
        <LayersIcon className="icon-styling" />
        <div className="count">
          <Typography variant="body2" className="main-title">
            {" "}
            {title}{" "}
          </Typography>
          <Typography variant="body1" className="total-user-name">
            {" "}
            {count}{" "}
          </Typography>
        </div>
      </div>
    );
  };

  const CustomCard = ({ title, data }) => {
    return (
      <div className="all-details-custom-card">
        <Typography variant="body1" className="title-container">
          {title}
        </Typography>
        <div className="details-data">
          {title === analytics.totalRevenue && (
            <div className="revenue-titles">
              <Typography variant="body2" className="revenue-title">
                {pricingPlan.revenueTitle}
              </Typography>
              <Typography variant="body2" className="revenue-title">
                {pricingPlan.revenuePrice}
              </Typography>
              <Typography variant="body2" className="revenue-title">
                {pricingPlan.userAccounts}
              </Typography>
            </div>
          )}
          {data?.map((dataItem) => (
            <div key={dataItem._id} className="data-item">
              <Typography variant="body2" className="title">
                {title === analytics.totalRevenue
                  ? dataItem.title
                  : dataItem.role}
              </Typography>
              <Typography variant="body2" className="title">
                {title === analytics.totalRevenue && dataItem.price}
              </Typography>
              <Typography variant="body2" className="title">
                {title === analytics.totalRevenue && dataItem.userLimit}
              </Typography>
              <Typography variant="body1">{dataItem.usersCount}</Typography>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const SubCustomCard = ({ title, description, item }) => {
    return (
      <div className="user-details-custom-card">
        <Typography variant="body1" className="mainTitle">
          {" "}
          {title}{" "}
        </Typography>
        <Typography className="description-styling"> {description} </Typography>
        <div key={item}>
          <BasicTable data={item} title={title} />
        </div>
      </div>
    );
  };

  function getColorForPaymentType(paymentType) {
    switch (paymentType) {
      case "prime":
        return "#EE82EE" ;
      case "Premium":
        return "#87CEFA";
      case "supreme":
        return "#FFC0CB";
      default:
        return "gray"; 
    }
  }

  const CustomXAxisTick = ({ x, y, payload }) => {
    const dateString = payload.value;
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayName = dayNames[dayOfWeek];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (chartType === 'month') {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {monthNames[date.getMonth()]}
          </text>
        </g>
      );
    } else {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {dayName}
          </text>
        </g>
      );
    }
  };

  const chartData = filteredData.map((item) => {
    const chartDataItem = {
      date: item._id,
    };

    item.data.forEach(dataItem => {
      chartDataItem[`${dataItem.paymentType}_${dataItem.companyName}`] = dataItem.price;
    });
    return chartDataItem;
  });

  function getUniquePaymentTypes(data) {
    const uniquePaymentTypes = new Set();
    data.forEach(item => {
      item.data.forEach(dataItem => {
        uniquePaymentTypes.add(dataItem.paymentType);
      });
    });
    return Array.from(uniquePaymentTypes);
  }

  const uniquePaymentTypes = getUniquePaymentTypes(filteredData);

  return (
    <div className="dashboard-main-card-container">
      <div className="total-user-main-container">
        <TotalUserCard
          title={analytics.totalRegisterUsers}
          count={userCount[0]?.TotalRegisterUsers}
        />
        {["AD", "SAD"].includes(
          secureLocalStorage.getItem(storageConstants.role)
        ) && (
          <>
            <TotalUserCard
              title="Total Admin Users"
              count={userCount[0]?.TotalAdmins}
            />
            <TotalUserCard
              title="Total Companies"
              count={userCount[0]?.TotalCompanies}
            />
            <TotalUserCard
              title="Total Active Users"
              count={userCount[0]?.TotalActiveUsers}
            />
          </>
        )}
        {["CM"].includes(
          secureLocalStorage.getItem(storageConstants.role)
        ) && (
          <CustomCard title={analytics.totalCompanyUsers} data={companyUsers} />
        )}
        <CustomCard title={analytics.totalRevenue} data={pricingPlans} />
        {["CM"].includes(
          secureLocalStorage.getItem(storageConstants.role)
        ) && (
          <SubCustomCard
            item={companyUsers}
            title={analytics.newUserRegistration}
            description={analytics.registerUserDescription}
          />
        )}

       <div className='user-details-custom-card'>
          <div className="dropdown-parent-container">
            <DropdownField
                label="Select Time Period"
                value={chartType}
                onChangeEvent={handleChartTypeChange}
                data={[
                    { id : "day",   title : "Week" },
                    { id : "month", title : "Month" }
                    ]}
                    width={150}/>
          </div>
          <div className="barChart-parent-container">      
                 <BarChart width={600} height={165} data={chartData}>
                  <XAxis 
                        dataKey="date" 
                        tick={<CustomXAxisTick />} />
                    <YAxis>
                      <Label
                          value="Price"
                          position="insideLeft"
                          angle={-90}
                          offset={5}
                          style={{ fontWeight: 'bold' }}/>
                    </YAxis>
                    <Tooltip
                        formatter={(value, name, props) => {
                          return [`${props.dataKey}: ${value}`, props.payload.companyName];
                        }}/>
                    <Legend
                        iconSize={15} 
                        iconType="square"
                        payload={uniquePaymentTypes.map((paymentType) => ({
                        value: paymentType,
                        type: 'square',
                        color: getColorForPaymentType(paymentType),
                        }))}/>
                      
                      {filteredData?.map((item) => (
                          item?.data?.map(dataItem => {
                            const { paymentType, companyName } = dataItem;
                            const key = `${paymentType}_${companyName}`;
                            return (
                              <Bar
                                key={key}
                                dataKey={key}
                                name={`${paymentType}`}
                                fill={getColorForPaymentType(paymentType)}
                                barSize={20}>  
                                <LabelList
                                  dataKey={key}
                                  position="top"
                                  content={data => data.value}/>
                              </Bar>
                            );
                          })
                        ))}
                 </BarChart>
          </div>
       </div>  
        {["AD", "SAD"].includes(
          secureLocalStorage.getItem(storageConstants.role)
        ) && (
          <SubCustomCard
            item={endUsers}
            title={analytics.newUserRegistration}
            description={analytics.registerUserDescription}
          />
        )}
        <SubCustomCard
          title={analytics.redeemRequest}
          description={analytics.withdrawRequest}
        />
      </div>
    </div>
  );
}
