import React from 'react';
import TableComponent from '../tableComponent/TableComponent';
import { CompanyProjectsContainer } from './Container';
import { companyProjectsUrlConstants } from './Constants';
import { companyProjectColumns } from '../../utils/tableColumns/companyProjects';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const CompanyProjectsTable = () => {
  const companyProjectColumnFields = companyProjectColumns();
  const {
    showDeleteConfirmation,
    onClickCompanyConfigAddForm,
    onClickCompanyConfigEditForm,
    onClickCompanyConfigViewForm,
    handleDeleteClick,
    handleDeleteConfirm,
    handleDeleteCancel
  } = CompanyProjectsContainer();

  return (
    <>
      <Dialog
        open={showDeleteConfirmation}
        onClose={handleDeleteCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color='primary' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <TableComponent
        columnFields={companyProjectColumnFields}
        url={companyProjectsUrlConstants.getTableDataUrl}
        onClickDelete={handleDeleteClick}
        onClickAddForm={onClickCompanyConfigAddForm}
        onClickEditForm={onClickCompanyConfigEditForm}
        onClickViewForm={onClickCompanyConfigViewForm}
      />
    </>
  );
};

export default CompanyProjectsTable;