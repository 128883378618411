import { apiServices } from "../../service/apiService";
import { platformAdminUrlConstants } from "./Constants";

const getSelectedPlatformAdminDataService = async (id) => {
  return await apiServices.getApi(
    `${platformAdminUrlConstants.getSelectedCompanyDataUrl}/${id}`
  );
};

const savePlatformAdminDataService = async (data) => {
  return await apiServices.postApi(
    platformAdminUrlConstants.saveCompanyDataurl,
    data
  );
};

const updatePlatformAdminDataService = async (data) => {
  return await apiServices.updateApi(
    platformAdminUrlConstants.updateCompanyDataurl,
    data
  );
};

const deletePlatformAdminDataService = async (id) => {
  return await apiServices.deleteApi(
    `${platformAdminUrlConstants.deleteCompanyDataUrl}/${id}`
  );
};

export const platformAdminService = {
  getSelectedPlatformAdminDataService,
  savePlatformAdminDataService,
  updatePlatformAdminDataService,
  deletePlatformAdminDataService,
};
