import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { Add as AddIcon } from '@mui/icons-material';

const InputFileUpload = ({
  acceptType = "image/*",
  // acceptType = "image/*",
  label,
  isMultiple = false,
  onChangeEvent,
  isRequired = false,
  isDisabled = false,
  initialFileUpload = false
}) => {
  return (
  <>  
  {initialFileUpload ?
    <Tooltip title={`Add ${label}`} arrow placement="top" >
       <Button
          component="label"
          variant="contained"
          startIcon={<AddIcon  className="tb-act-icons-add"/>}
          sx={{ m: 1, background: "#00000099", height: 70, borderRadius:35, display:"flex", flexDirection:'row', justifyContent:"center", alignItems:"center"}}
        >
          {`${isRequired ? "*" : ""}`}
          <input
            hidden
            accept={acceptType}
            multiple={isMultiple}
            type="file"
            onChange={onChangeEvent}
            disabled={isDisabled}
          />
        </Button>
      </Tooltip> :
      <Button
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
      href="#file-upload"
      sx={{ m: 1, background: "#00000099", height: 100 }}
    >
      {label} {`${isRequired ? "*" : ""}`}
      <input
        hidden
        accept={acceptType}
        multiple={isMultiple}
        type="file"
        onChange={onChangeEvent}
        disabled={isDisabled}
      />
    </Button>}
   </>
  );
};

export default InputFileUpload;
