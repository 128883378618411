export const analyticsTableTitles={
    email: 'Email',
    mobile: 'Mobile',
    name: 'Name',
    companyName: 'Company Name',
    coin: 'Coin'
}

export const analytics = {
    newUserRegistration :'NEW REGISTRATION',
    registerUserDescription: 'Manage register user here',
    withdrawRequest: 'Manage withdrawal request here',
    redeemRequest: 'RECENT REDEEM REQUEST',
    totalCompanyUsers: 'Total Company Users',
    totalRegisterUsers: 'Total Register Users',
    totalRevenue: 'Total Revenue'
}

 export const pricingPlan = {
    revenueTitle : 'Title',
    revenuePrice : 'Price',
    userAccounts :'userAccounts'
 }