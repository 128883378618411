import React from 'react';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import InputFileUpload from './InputFileUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const UploadFiles = ({
  label,
  rowData,
  onChangeEvent,
  uploadLabel,
  acceptType,
  initialFileUpload,
  isDisabled,
  onRemoveFile
}) => {
  const fileNames = rowData?.map((url) => {
    const name = url.split('/');
    const fileName = name[name.length - 1];
    return fileName;
  });

  return (
    <>
      <Typography className='global-form-text'>{label}</Typography>
      <Grid
        sx={{
          display: 'flex'
        }}>
        <InputFileUpload
          label={uploadLabel}
          onChangeEvent={onChangeEvent}
          isRequired={true}
          acceptType={acceptType}
          initialFileUpload={initialFileUpload}
          isDisabled={isDisabled}
        />
        {rowData ? (
          <>
            {rowData?.map((url, index) => (
              <div key={index} style={{display:'flex',flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <Typography
                  sx={{
                    color: '#00000099',
                    fontSize: 16,
                    color: '#00000099',
                    fontWeight: 'bold',
                    fontStyle: 'italic'
                  }}>
                  {`${fileNames[index]}`}
                </Typography>
                <CancelIcon onClick={() => onRemoveFile(index)} sx={{color:"#ff0000",fontSize:16}} />
                <Typography>
                  {`${index < rowData.length - 1 ? '/ ' : '.'}`}
                </Typography>
              </div>
            ))}
          </>
        ) : (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: '#00000099',
              fontSize: 12
            }}>
            {uploadLabel}
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default UploadFiles;