import { Grid, Typography } from "@mui/material";
import React, { useCallback } from "react";

import "./Style.scss";
import { DashboardContainer } from "./Container";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Company from "../company/Company";
import SnackBar from "../../common/SnackBar";
import User from "../user/User";
import PricingPlan from "../pricingPlan/PricingPlan";
import { useSelector } from "react-redux";
import CircleLoader from "../../common/CircleLoader";
import Analytics from "../analytics/Analytics";
import CompanyAdminUser from "../companyAdminUser/CompanyAdminUser";
import PlatformAdminUser from "../platformAdminUser/PlatformAdminUser";
import Campaign from "../campaign/Campaign";
import Product from "../product/Product";
import AboutUs from "../aboutUs/AboutUs";
import RefundPolicy from "../refundPolicy/RefundPolicy";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import PushNotification from "../pushNotification/PushNotification";
import Faq from "../faq/Faq";
import AppDetails from "../appDetails/AppDetails";
import NewUpdate from "../newUpdate/NewUpdate";
import ContactUs from "../contantUs/ContantUs";
import Games from "../games/Games";
import TermsandConditions from "../termsandConditions/TermsandConditions";
import MatchList from "../matchList/MatchList";
import CompletedMatchList from "../completedMatchList/CompletedMatchList";
import WithdrawMatchList from "../withdrawMatchList.js/WithdrawMatchList";
import CompanyConfig from "../companyConfig/CompanyConfig";
import CompanyProjects from "../CompanyProjects/CompanyProjects"
import AppConfiguration from "../appConfiguration/appConfiguration";
import MetaRobDashboard from "../metaRob/MetaRob";
import MetaRobDataDashboard from "../metaRobData/MetaRobData";


const Dashboard = () => {
	const { index, onChangeIndex, showSideMenu } = DashboardContainer();
	const { isLoading } = useSelector((state) => state.app);

	const renderComponents = useCallback(() => {
		switch (index) {
			case 0:
				document.title = "Aauti Verse | Dashboard";
				return <Analytics />;
			case 1:
				document.title = "Aauti Verse | Company";
				return <Company />;
			// case 2:
			// 	document.title = "Aauti Verse | Pricing Plan";
			// 	return <PricingPlan />;
			// case 3:
			// 	document.title = "Aauti Verse | Line Plans";
			// 	return <Typography>Line Plans</Typography>;
			case 4:
				document.title = "Aauti Verse | User";
				return <User />;
			case 5:
				document.title = "Aauti Verse | License";
				return (
					<Grid>
						<Typography>License</Typography>
					</Grid>
				);
			// case 6:
			//   document.title = "Aauti Verse | Configurations";
			//   return <Typography>Configurations</Typography>;
			case 7:
				document.title = "Aauti Verse | Admin User";
				return <PlatformAdminUser />;
			case 8:
				document.title = "Aauti Verse | Admin User";
				return <CompanyAdminUser />;
			// case 9:
			//   document.title = "Aauti Verse | Campaign";
			//   return <Campaign />;
			// case 10:
			//   document.title = "Aauti Verse | Product List";
			//   return <Product />;
			// case 11:
			// 	document.title = "Aauti Verse | About Us";
			// 	return <AboutUs />;
			// case 12:
			// 	document.title = "Aauti Verse | Contact Us";
			// 	return <ContactUs />;
			// case 13:
			// 	document.title = "Aauti Verse | Privacy Policy";
			// 	return <PrivacyPolicy />;
			// case 14:
			// 	document.title = "Aauti Verse | Refund Policy";
			// 	return <RefundPolicy />;
			// case 15:
			// 	document.title = "Aauti Verse | Terms and Condition";
			// 	return <TermsandConditions />;
			// case 16:
			// 	document.title = "Aauti Verse | Push Notification";
			// 	return <PushNotification />;
			// case 17:
			// 	document.title = "Aauti Verse | New Update";
			// 	return <NewUpdate />;
			// case 18:
			// 	document.title = "Aauti Verse | FAQ";
			// 	return <Faq />;
			// case 19:
			// 	document.title = "Aauti Verse | App Details";
			// 	return <AppDetails />;
			// case 20:
			//   document.title = "Aauti Verse | Match List";
			//   return <MatchList />;
			// case 21:
			//   document.title = "Aauti Verse | Completed Match List";
			//   return <CompletedMatchList />;
			// case 22:
			//   document.title = "Aauti Verse | Withdraw Match List";
			//   return <WithdrawMatchList />;
			// case 23:
			//   document.title = "Aauti Verse | Games";
			//   return <Games />;
			// case 24:
			// 	document.title = "Aauti Verse | Company Config";
			// 	return <CompanyConfig />;
			case 25:
				document.title = "Aauti Verse | Company Projects";
				return <CompanyProjects />;
			case 27:
				document.title = "Aauti Verse | App Configuration";
				return <AppConfiguration />;
			case 28:
				document.title = "Aauti Verse | MetaRob";
				return <MetaRobDashboard/>
			case 29:
				document.title = "Aauti Verse | MetaRob Data";
				return <MetaRobDataDashboard/>
				
			default:
				document.title = "Aauti Verse | Dashboard";
				return <Analytics />;
		}
	}, [index]);

	return (
		<Grid className="dashboard-container">
			{isLoading && <CircleLoader />}
			<Grid
				className={`${
					showSideMenu ? "side-container side-container_show" : "side-container"
				}`}
				// position="fixed"
			>
				<Sidebar index={index} onChangeIndex={onChangeIndex} />/
			</Grid>

			<Grid
				className={`${
					showSideMenu
						? "dashboard-data-container dashboard-data-container_show"
						: "dashboard-data-container"
				}`}
				position="fixed"
			>
				<Header />
				{renderComponents()}
				<SnackBar />
			</Grid>
		</Grid>
	);
};

export default Dashboard;
