import React from "react";
import TableComponent from "../tableComponent/TableComponent";
import { PlatformAdminUserContainer } from "./Container";
import { platformAdminUrlConstants } from "./Constants";
import { plaformAdminColumns } from "../../utils/tableColumns/platformAdmin";

const PlatformAdminUserTable = () => {
  const companyColumnFields = plaformAdminColumns();
  const {
    onClickDelete,
    onClickPlatformAdminAddForm,
    onClickPlatformAdminEditForm,
    onClickPlatformAdminViewForm,
  } = PlatformAdminUserContainer();

  return (
    <TableComponent
      columnFields={companyColumnFields}
      url={platformAdminUrlConstants.getTableDataUrl}
      onClickDelete={onClickDelete}
      onClickAddForm={onClickPlatformAdminAddForm}
      onClickEditForm={onClickPlatformAdminEditForm}
      onClickViewForm={onClickPlatformAdminViewForm}
    />
  );
};

export default PlatformAdminUserTable;
