import { companyConfigLabels } from "../../assets/labels/companyConfig";
import { companyProjectLabels } from "../../assets/labels/companyProjects";

export const companyProjectColumns = () => {
  const company_config_columns = [
   
    {
      field: companyProjectLabels.companyId_filed,
      headerName: companyProjectLabels.companyId ,
    },
    {
      field: companyProjectLabels.company_name_field,
      headerName: companyProjectLabels.company_name
    },
    {
      field: companyProjectLabels.description_field,
      headerName: companyProjectLabels.description
    }
  ];

  return company_config_columns;
};