import { useState } from "react";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { storageConstants } from "../../utils/globalConstant";

export const DashboardContainer = () => {
  const [index, setIndex] = useState(
    secureLocalStorage.getItem(storageConstants.selectedOption) === null
      ? 0
      : secureLocalStorage.getItem(storageConstants.selectedOption)
  );
  const { showTable, showSideMenu } = useSelector((state) => state.app);

  const onChangeIndex = (value) => {
    if (showTable) {
      setIndex(value);
      secureLocalStorage.setItem(storageConstants.selectedOption, value);
    }
  };

  return {
    index,
    onChangeIndex,
    showSideMenu,
  };
};
