import { Box, Grid, Tab, Typography } from "@mui/material";
import React from "react";
import "../../themes/globalStyle.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DialogBox from "../../common/DialogBox";
import TextInputField from "../../common/TextInputField";
import { UserContainer } from "./Container";
import FormActionButton from "../../common/FormActionButton";
import { userLabels } from "../../assets/labels/user";
import DropdownField from "../../common/DropdownField";
import { userConstants } from "./Constants";
import InputFileUpload from "../../common/InputFileUpload";
import NumericInputField from "../../common/NumericInputField";

const UserForm = () => {
  const {
    onChangeFirstName,
    onChangeLastName,
    onChangeEmail,
    onChangeRole,
    onChangeMobile,
    tabIndex,
    onChangeTabIndex,
    showViewForm,
    showEditForm,
    onClickSave,
    onClickReset,
    rowData,
    onChangeProfileImage,
    onChangeAge,
    onChangeGender,
    onChangeStatus,
  } = UserContainer();

  return (
    <Grid className="global-form">
      <Box
        className="global-form-container"
        sx={{
          typography: "body1",
          height: "95%",
        }}
      >
        <TabContext value={tabIndex}>
          <Box className="global-form-tab-header">
            <TabList
              onChange={onChangeTabIndex}
              aria-label="lab API tabs example"
            >
              <Tab label="User Form" value="1" />
            </TabList>
          </Box>
          <TabPanel value="1" className="global-form-fields-container">
            <TextInputField
              label={userLabels.user_first_name}
              value={rowData?.firstName}
              onChangeEvent={onChangeFirstName}
              isRequired={true}
              isDisabled={showViewForm}
            />
            <TextInputField
              label={userLabels.user_last_name}
              value={rowData?.lastName}
              onChangeEvent={onChangeLastName}
              isRequired={false}
              isDisabled={showViewForm}
            />
            <TextInputField
              label={userLabels.user_email}
              value={rowData?.email}
              onChangeEvent={onChangeEmail}
              isRequired={true}
              isDisabled={showViewForm || showEditForm}
            />
            <NumericInputField
              label={userLabels.user_age}
              value={rowData?.age}
              onChangeEvent={onChangeAge}
              isDisabled={showViewForm}
              isRequired={false}
            />
            <DropdownField
              label={userLabels.user_gender}
              value={rowData?.gender}
              onChangeEvent={onChangeGender}
              data={userConstants.userGenders}
              isDisabled={showViewForm}
              isRequired={false}
            />
            <DropdownField
              label={userLabels.user_status}
              value={rowData?.active}
              onChangeEvent={onChangeStatus}
              data={userConstants.status}
              isDisabled={showViewForm}
              isRequired={false}
            />
            <DropdownField
              label={userLabels.user_role}
              value={rowData?.role}
              onChangeEvent={onChangeRole}
              data={userConstants.userRoles}
              isDisabled={showViewForm}
              isRequired={true}
            />

            <TextInputField
              label={userLabels.user_mobile}
              value={rowData?.mobile}
              onChangeEvent={onChangeMobile}
              isRequired={true}
              isDisabled={showViewForm}
            />
            <Grid
              sx={{
                display: "flex",
              }}
            >
              <InputFileUpload
                label={userLabels.user_image}
                onChangeEvent={onChangeProfileImage}
                isRequired={false}
                isDisabled={showViewForm}
              />

              {rowData?.image ? (
                <img
                  src={rowData?.image}
                  alt="cover_image"
                  style={{
                    width: 95,
                    height: 95,
                    margin: 8,
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    // border: "1px solid red",
                    display: "flex",
                    alignItems: "center",
                    color: "#00000099",
                    fontSize: 12,
                  }}
                >
                  No image uploaded
                </Typography>
              )}
            </Grid>
          </TabPanel>
          <FormActionButton
            onClickSave={onClickSave}
            onClickReset={onClickReset}
          />
        </TabContext>
        <DialogBox />
      </Box>
    </Grid>
  );
};

export default UserForm;
