import { useEffect, useState } from 'react';
import { propertyConstants, propertyUrlConstants } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedRowData,
	toggleLoadingView,
	toggleToastView,
} from '../../redux/reducer/appReducer';
import { propertyService } from './Service';
import { getTableData } from '../tableComponent/tableComponent.slice';
import { CommonContainer } from '../../utils/CommonFunction';
import { companyProjectsService } from '../CompanyProjects/Service';

export const PropertyContainer = () => {
	const { showEditForm, showAddForm, showViewForm, selectedRowData } =
		useSelector((state) => state.app);
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState('1');
	const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
		CommonContainer();

	const propertyInfo = {
		companyId: "",
		propertyID: '',
		propertyName: '',
		propertyType: '',
		facing: '',
		capacity: '',
		aminities: [],
		description: '',
		price: 0
	}

	const [rowData, setRowData] = useState(propertyInfo);
	const [resetData, setResetData] = useState({});
	const [companyData, setCompanyData] = useState([]);
	const [propertyTypeData, setPropertyTypeData] = useState([]);
	const [propType, setPropType] = useState([]);
	const [propertyFacingValues, setPropertyFacingValues] = useState([]);
	const [facing, setFacing] = useState([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const getSelectedRowData = async () => {
		dispatch(toggleLoadingView(true));
		const data =
			await propertyService.getSelectedPropertyDataService(
				selectedRowData?._id
			);
		setRowData(data.result);
		setResetData(data.result);
		dispatch(toggleLoadingView(false));
	};

	const getCompanyData = async () => {
		const data = await companyProjectsService.getCompanyNamesService();
		setCompanyData(data.result);
		console.log("companyId----", data.result)
		showAddForm && setRowData({ ...rowData, companyId: data.result[0]?.id });
	};

	const getPropertyTypeData = async () => {
		const data = await propertyService.getPropertyTypeService();
		setPropType(data.result);
		let temp = [];
		console.log('fcdfffd',data.result)
		await data?.result.map((each) =>
		  temp.push({
			id: each?._id,
			title: each?.propTypeTitle,
		  })
		);
		setPropertyTypeData(temp);
		console.log("temp----", temp);
	  };

	  const getFacingData = async () => {
		const data = await propertyService.getFacingService();
		setFacing(data.result);
		let temp = [];
		console.log('fcdfffd',data.result)
		await data?.result.map((each) =>
		  temp.push({
			id: each?._id,
			title: each?.facingTitle,
		  })
		);
		setPropertyFacingValues(temp);
	  };

	useEffect(() => {
		if (showEditForm || showViewForm) {
			getSelectedRowData();
		  }
		  if (showEditForm || showViewForm || showAddForm) {
			getCompanyData();
		  }
		  if (showEditForm || showViewForm || showAddForm) {
			getPropertyTypeData();
		  }
		  if (showEditForm || showViewForm || showAddForm) {
			getFacingData();
		  }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeTabIndex = (event, newValue) => {
		setTabIndex(newValue);
	};

	const onChangeCompany = (event) => {
		const newCompanyId = event.target.value;
		if (rowData.companyId !== newCompanyId) {
			setRowData({
				...propertyInfo,
				companyId: newCompanyId,
				propertyID: '',
				propertyName: '',
				propertyType: '',
				facing: '',
				capacity: '',
				aminities: [],
				description: '',
				price: 0
			});
		} else {
			setRowData({ ...rowData, companyId: newCompanyId });
		}
	};

	// debugger;
	const onChangePropertyId = (event) => {
		setRowData({ ...rowData, propertyID: event.target.value });
	};

	const onChangePropertyName = (event) => {
		setRowData({ ...rowData, propertyName: event.target.value });
	};

	const onChangePropertyType = (event) => {
		const data = propType.filter(
		  (each) => each._id === event.target.value
		)[0];
		console.log('data---', data)
	    
		setRowData({
		  ...rowData,
		  propertyType: event.target.value,
		});
	  };

	const onChangeFacing = (event) => {
		setRowData({ ...rowData, facing: event.target.value });
	};
	const onChangeCapacity = (event) => {
		setRowData({ ...rowData, capacity: event.target.value });
	};
	const onChangeAminities = (event) => {
		setRowData({ ...rowData, aminities: event.target.value });
	};
	const onChangeDescription = (event) => {
		setRowData({ ...rowData, description: event.target.value });
	};
	const onChangePrice = (event) => {
		setRowData({ ...rowData, price: event.target.value });
	};


	const onClickSave = async () => {
		if (rowData?.propertyID.trim() === "" ||
			rowData?.propertyName.trim() === "" ||
			rowData?.propertyType.trim() === "" ||
			rowData?.facing.trim() === "" ||
			rowData?.capacity.trim() === "" ||
			rowData?.aminities.trim() === "" ||
			rowData?.description.trim() === "" ||
			rowData?.price.trim() === ""
		) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: "* Please fill all the required fields",
					isError: true,
				})
			);
		} else {
			dispatch(toggleLoadingView(true));
			let response;
			if (showAddForm) {
				response = await propertyService.savePropertyDataService(rowData);
			} else {
				response = await propertyService.updatePropertyDataService(rowData);
			}
			if (response.result.length === 0) {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: true,
					})
				);
			} else {
				dispatch(
					toggleToastView({
						showToast: true,
						message: response.message,
						isError: false,
					})
				);
				await dispatch(getTableData(propertyUrlConstants.getTableDataUrl));
				onClickShowTable();
			}
			dispatch(toggleLoadingView(false));
		}
	};

	const onClickReset = () => {
		setRowData(resetData);
	};

	const onClickPropertyAddForm = () => {
		onClickAddForm();
	};
	const onClickPropertyEditForm = () => {
		onClickEditForm();
	};
	const onClickPropertyViewForm = () => {
		onClickViewForm();
	};

	const onClickDelete = async () => {
		dispatch(toggleLoadingView(true));
		const response = await propertyService.deletePropertyDataService(
			selectedRowData?._id
		);
		dispatch(setSelectedRowData({}));
		if (response.error) {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: true,
				})
			);
		} else {
			dispatch(
				toggleToastView({
					showToast: true,
					message: response.message,
					isError: false,
				})
			);
			await dispatch(getTableData(propertyUrlConstants.getTableDataUrl));
			dispatch(toggleLoadingView(false));
		}
	};

	const handleDeleteClick = () => {
		setShowDeleteConfirmation(true);
	  };

	const handleDeleteConfirm = () => {
		onClickDelete();
		setShowDeleteConfirmation(false);
	  };
	
	  const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	  };

	return {
		onClickPropertyAddForm,
		onClickPropertyEditForm,
		onClickPropertyViewForm,
		onClickDelete,
		showDeleteConfirmation,
		handleDeleteClick,
		handleDeleteConfirm,
		handleDeleteCancel,
		onChangePropertyId,
		onChangePropertyName,
		onChangePropertyType,
		onChangeFacing,
		onChangeCapacity,
		onChangeAminities,
		onChangeDescription,
		onChangePrice,
		tabIndex,
		onChangeTabIndex,
		showViewForm,
		showEditForm,
		showAddForm,
		rowData,
		onClickSave,
		onClickReset,
		onChangeCompany,
		companyData,
		propertyTypeData,
		propertyFacingValues
	};
};