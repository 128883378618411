import { useEffect, useState } from "react";
import { platformAdminConstants, platformAdminUrlConstants } from "./Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRowData,
  toggleLoadingView,
  toggleToastView,
} from "../../redux/reducer/appReducer";
import { platformAdminService } from "./Service";
import { getTableData } from "../tableComponent/tableComponent.slice";
import { CommonContainer } from "../../utils/CommonFunction";
import { imageUpload } from "../../utils/helper";

export const PlatformAdminUserContainer = () => {
  const { showEditForm, showAddForm, showViewForm, selectedRowData } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState("1");
  const { onClickShowTable, onClickAddForm, onClickEditForm, onClickViewForm } =
    CommonContainer();

  const companyInfo = {
    username: "",
    email: "",
    role: platformAdminConstants.companyRoles[0].id,
    image: "",
    active: true,
  };
  const [rowData, setRowData] = useState(companyInfo);
  const [resetData, setResetData] = useState({});

  const getSelectedRowData = async () => {
    dispatch(toggleLoadingView(true));
    const data = await platformAdminService.getSelectedPlatformAdminDataService(
      selectedRowData?._id
    );
    setRowData(data.result);
    setResetData(data.result);
    dispatch(toggleLoadingView(false));
  };

  useEffect(() => {
    if (showEditForm || showViewForm) {
      getSelectedRowData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onChangeUsername = (event) => {
    setRowData({ ...rowData, username: event.target.value });
  };
  const onChangeEmail = (event) => {
    setRowData({ ...rowData, email: event.target.value });
  };
  const onChangeRole = (event) => {
    setRowData({ ...rowData, role: event.target.value });
  };

  const onChangeStatus = (event) => {
    setRowData({ ...rowData, active: event.target.value });
  };

  const onChangeProfileImage = async (event) => {
    dispatch(toggleLoadingView(true));
    const res = await imageUpload(event);
    if (res.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "Please upload again",
          isError: true,
        })
      );
    } else {
      setRowData({ ...rowData, image: res?.result?.image });
    }
    dispatch(toggleLoadingView(false));
  };

  const onClickSave = async () => {
    if (rowData?.username.trim() === "" || rowData?.email.trim() === "") {
      dispatch(
        toggleToastView({
          showToast: true,
          message: "* Please fill the required fields",
          isError: true,
        })
      );
    } else {
      dispatch(toggleLoadingView(true));
      let response;
      if (showAddForm) {
        response = await platformAdminService.savePlatformAdminDataService(
          rowData
        );
      } else {
        response = await platformAdminService.updatePlatformAdminDataService(
          rowData
        );
      }
      if (response.result.length === 0) {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: true,
          })
        );
      } else {
        dispatch(
          toggleToastView({
            showToast: true,
            message: response.message,
            isError: false,
          })
        );
        await dispatch(getTableData(platformAdminUrlConstants.getTableDataUrl));
        onClickShowTable();
      }
      dispatch(toggleLoadingView(false));
    }
  };
  const onClickReset = () => {
    setRowData(resetData);
  };

  const onClickPlatformAdminAddForm = () => {
    onClickAddForm();
  };
  const onClickPlatformAdminEditForm = () => {
    onClickEditForm();
  };
  const onClickPlatformAdminViewForm = () => {
    onClickViewForm();
  };

  const onClickDelete = async () => {
    dispatch(toggleLoadingView(true));
    const response = await platformAdminService.deletePlatformAdminDataService(
      selectedRowData?._id
    );
    dispatch(setSelectedRowData({}));
    if (response.error) {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: true,
        })
      );
    } else {
      dispatch(
        toggleToastView({
          showToast: true,
          message: response.message,
          isError: false,
        })
      );
      await dispatch(getTableData(platformAdminUrlConstants.getTableDataUrl));
      dispatch(toggleLoadingView(false));
    }
  };

  return {
    onChangeUsername,
    onChangeEmail,
    onChangeRole,
    tabIndex,
    onChangeTabIndex,
    showViewForm,
    showEditForm,
    onClickSave,
    onClickReset,
    rowData,
    onClickDelete,
    onClickPlatformAdminAddForm,
    onClickPlatformAdminEditForm,
    onClickPlatformAdminViewForm,
    onChangeProfileImage,
    onChangeStatus,
  };
};
