export const apiStatus = {
	idle: "idle",
	loading: "loading",
	success: "success",
	failed: "failed",
};

export const storageConstants = {
	email: "_email",
	password: "_password",
	role: "_role",
	companyId: "_companyId",
	selectedOption: "_selectedOption",
	forgotPasswordEmail: "forgotPasswordEmail",
	setTimer: "setTimer",
	error: "error",
	emptyString: "",
	lightMode: "_light_mode",
	secretkey: "secretkey",
};

export const fileUploadPaths = {
	imagePath: (companyId) => `${companyId}/images`,
	androidPath: (companyId) => `${companyId}/android`,
	iosPath: (companyId) => `${companyId}/ios`,
	windowsPath: (companyId) => `${companyId}/windows`,
	webglPath: (companyId) => `${companyId}/webgl`,
	macPath: (companyId) => `${companyId}/mac`,
};
