import { useState, useEffect } from "react";
import { usersCountService } from "./Service";

export default function AnalyticsFunctionality() {
  const [userCount, setUsersCount] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [endUsers, setEndUsers] = useState([]);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [chartType, setChartType] = useState("day");
  const [barChartPricingPlans, setBarChartPricingPlans] = useState([]);

  useEffect(() => {
    fetchData();
    getPricingDetails(chartType);
  }, [chartType]);

  const fetchData = async (data) => {
    try {
      const totalUsersResponse = await usersCountService.TotalUsersCount();
      const totalCompanyUsersResponse =
        await usersCountService.companyUserDetails();
      const endUsersResponse = await usersCountService.getAllEndUserDetails();
      const pricingPlansResponse = await usersCountService.getAllPricingPlans(
        data
      );
      setUsersCount(totalUsersResponse);
      setCompanyUsers(totalCompanyUsersResponse);
      setEndUsers(endUsersResponse);
      setPricingPlans(pricingPlansResponse);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPricingDetails = async (data) => {
    const barChartPricingPlansResponse =
      await usersCountService.getAllPricingPlansForCompany(data);
    setBarChartPricingPlans(barChartPricingPlansResponse);
  };

  const handleChartTypeChange = (event) => {
    setBarChartPricingPlans([]);
    setChartType(event.target.value);
  };

  return {
    userCount,
    companyUsers,
    endUsers,
    pricingPlans,
    chartType,
    setChartType,
    handleChartTypeChange,
    barChartPricingPlans,
  };
}
